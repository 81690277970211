import { Injectable } from '@angular/core';
import { HttpService, StorageService, RestService, ApplicationsService } from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import * as moment from 'moment';
import cloneDeep from 'lodash-es/cloneDeep';
import sortBy from 'lodash-es/sortBy';
import orderBy from 'lodash-es/orderBy';
import chain from 'lodash-es/chain';

import { CommonAppealData } from './common-appeal.data';
import { UtilityService } from '../../../common/services/utility.service';
import { CommonAppealStatusService } from './common-appeal-status.service';
import { CommonAppealSaveService } from './common-appeal-save.service';
import { ServicesService } from '../../subservices/services/services.service';
import { Config } from '../../../common/services/config';

@Injectable()
export class CommonAppealService {
    public appeal; // обрабатываемое дело
    public complexSubservice; // комплексная услуга, на основе которой формируется дело
    public subservice; // услуга, на основе которой формируется дело
    public currentOrganization = this.storage.getItem('currentOrganization'); // текущая организация

    public statuses = CommonAppealData.statuses; // возможные статусы
    public filtersPanelItems = CommonAppealData.filtersPanelItems; // параметры панели фильтров в списке дел
    public finishStatuses = CommonAppealData.finishStatuses; // набор кодов статусов являющихся финальными для дела

    public baseAppeal = null;
    public appealStatus: any = {};
    public appealSubstatus: any = {};
    public activeAppealAction;
    public appealActions;
    public appealPayments; // Платежи в деле
    public metaReglament;
    public useXsdService = true;

    public printForms = []; // печатные формы дела

    // Параметры для работы с групповыми операциями
    public groupOperation = null; // Текущая групповая операция

    public userPermissions = this.storage.getItem('userPermissions');

    public isProcessSetupAppealSubservices = false; // Режим настройки услуг в деле
    public selectedSubservicesForSetupInAppeal = []; // Массив выбранных услуг для добавления в дело

    public isProcessSelectSubserviceForCopyAppeal = false; // Режим замены услуги в копируемом деле на другую услугу
    public copyAppealParams; // Данные для копирования дела (возможно измененные в процессе обработки)
    public changeAppealSubserviceFromCopyAppeal; // Услуга, которая заменяется в деле
    public selectedSubserviceForCopyAppeal; // Выбранная услуга для замены в копируемом деле

    public appealCopy; // Дело сформированное процедурой копирования
    public subserviceFromAppealCopy; // Услуги скопированного дела

    public isProcessSelectAppealsForPacket = false; // Режим выбора дел для пакета (перевода дел в другое МФЦ)
    public packet; // пакет, в который добавляются дела

    public activeTabInAppealBeforeCreate;

    public isActiveProcessInAppeal = false;

    public processTasks = [];

    public allKnoUsers;

    public appealLinksData = {}; // объекты связанных с делом сущностей: событие, план проверки, программа проверки, надзорное дело

    public registersPath: string = 'registers';
    public appealsPath: string = 'appeals';

    public constructor(
        public httpService: HttpService,
        private rest: RestService,
        private storage: StorageService,
        private utility: UtilityService,
        private servicesService: ServicesService,
        private statusService: CommonAppealStatusService,
        private appealSaveService: CommonAppealSaveService,
        private applicationsService: ApplicationsService,
    ) {
    }

    /**
     * Очистка данных сервиса при редактировании дела
     */
    public clearData() {
        this.appeal = null;
        this.complexSubservice = null;
        this.subservice = null;
        this.appealPayments = null;
        this.metaReglament = null;
        this.appealLinksData = {};
    }

    /**
     * Корректировка комплексной услуги, пришедшей с сервера в соответствии с текущей организацией
     * @param complexSubservice
     * @returns
     */
    public correctComplexSubserviceByCurrentUnit(complexSubservice) {
        const currentOrganization = this.storage.getItem('currentOrganization');
        const result = {
            _id: complexSubservice._id,
            code: complexSubservice.code,
            title: complexSubservice.title,
            description: complexSubservice.description,
            lifeEvent: { id: complexSubservice.lifeEvent.id, name: complexSubservice.lifeEvent.title },
            subservices: [],
        };

        complexSubservice.standards.forEach(standard => {
            standard.reglaments.forEach(reglament => {
                const findUnit = reglament.units.find(item => item.id === currentOrganization._id);
                if (findUnit) {
                    const regl = {
                        id: reglament.id,
                        name: reglament.title,
                        displayOrder: standard.displayOrder,
                        startAfter: null,
                    };

                    // Определяем наличие возможности старта услуги только после выполнения зависимых услуг
                    if (standard.startAfter && standard.startAfter.length > 0) {
                        const startAfterReglaments = [];
                        standard.startAfter.forEach(code => {
                            const parentStandard = complexSubservice.standards.find(item => item.code === code);
                            parentStandard.reglaments.forEach(parentReglament => {
                                const reglamentUnit = parentReglament.units.find(item => item.id === currentOrganization._id);
                                if (reglamentUnit) {
                                    startAfterReglaments.push(parentReglament.id);
                                }
                            });
                        });
                        if (startAfterReglaments.length > 0) {
                            regl.startAfter = startAfterReglaments;
                        } else {
                            delete regl.startAfter;
                        }
                    }
                    result.subservices.push(regl);
                }
            });
        });
        // Cортировка услуг в соответствии со значениями поля displayOrder
        result.subservices = sortBy(result.subservices, ['displayOrder']);

        this.complexSubservice = result;

        return result;
    }

    /**
     * Корректировка услуги с учетом организации, под которой работает пользователь
     * @param service - обрабатываемая услуга
     * @returns {any}
     */
    public correctSubserviceByCurrentUnit(service) {
        return this.servicesService.correctSubserviceByCurrentUnit(service);
    }

    /**
     * Базовая инциализация дела при создании
     */
    public initAppeal() {
        const currentOrganization = this.storage.getItem('currentOrganization');
        this.appeal = {
            unitId: currentOrganization._id,
            unit: {
                id: currentOrganization._id,
                name: currentOrganization.name,
                shortName: currentOrganization.shortName,
            },
            pin: Math.floor(1000 + Math.random() * 9000).toString(),
            subservice: {},
            subjects: [],
            objects: [],
            documents: [],
            controlOperator: [],
            events: [],
            isCustomForm: true,
            dataForExecuteAction: {},
        };
        if (currentOrganization.region) {
            this.appeal.unit.region = {
                code: currentOrganization.region.code,
                name: currentOrganization.region.name,
            };
        }
        if (this.complexSubservice) {
            this.appeal.complexSubservice = {
                id: this.complexSubservice._id,
                name: this.complexSubservice.title,
            };
        }
        this.initStatusInAppeal();
        this.initSpecialDataInAppeal();

        return this.appeal;
    }

    public initStatusInAppeal() {
        let statuses = this.storage.getItem('defaultStatuses');
        if (this.subservice && this.subservice.statusModel) {
            statuses = this.subservice.statusModel.status;
        }
        this.appeal.status = this.statusService.initStatusData(statuses[0]); // Инициализация данных статуса в деле
        this.appeal.statusHistory = [this.appeal.status];
    }

    public initSpecialDataInAppeal() {
        const version = this.metaReglament ? this.metaReglament.code : 'knd';
        switch (version) {
            case 'knd':
                this.initKndDataInAppeal();
                break;
            default:
                break;
        }
    }

    public async initKndDataInAppeal() {
        if (!this.subservice) {
            return;
        }

        if (this.subservice.checkLists && this.subservice.checkLists[0] && this.subservice.checkLists[0].questions.length > 0) {
            this.appeal.isQuestions = true;
        }

        if (!(this.subservice.kndInfo && this.subservice.kndInfo.basisKnm)) {
            return;
        }

        for (let i = 0; i < this.subservice.kndInfo.basisKnm.length; i++) {
            const { name } = this.subservice.kndInfo.basisKnm[i];

            const npaTarget = await this.rest.search('nPA', {
                search: {
                    search: [{
                        field: 'name',
                        operator: 'eq',
                        value: name,
                    }],
                }, size: 100,
            });

            this.appeal.subservice.kndInfo.basisKnm[i] = npaTarget[0];
        }
    }

    /**
     * Инициализация услуги при создании дела (при выборе услуг из комплексной услуги)
     * @param subservice
     */
    public initSubserviceInAppeal(subservice) {
        const result: any = {
            id: subservice._id,
            variant: null,
            title: subservice.titles.title,
            shortTitle: subservice.titles.shortTitle,
            serviceId: subservice.serviceId,
            guid: CommonUtilities.GenerateGuid(),
            subjects: [],
            objects: [],
            entities: [],
        };
        if (subservice.classificSubserviceId) {
            result.classificSubserviceId = subservice.classificSubserviceId;
            result.classificSubserviceName = subservice.classificSubserviceName;
        }

        if (this.metaReglament) {
            result.version = this.metaReglament.code;
            if (this.metaReglament.isOldVersion) {
                if (this.metaReglament.appealsCollection) {
                    result.appealsCollection = this.metaReglament.appealsCollection;
                }
                if (this.metaReglament.registersCollection) {
                    result.registersCollection = this.metaReglament.registersCollection;
                }
                if (this.metaReglament.licensesCollection) {
                    result.registersCollection = this.metaReglament.licensesCollection;
                } else {
                    result.registersCollection = 'licenses';
                }
            } else {
                const appealData = this.metaReglament.blocks.find(item => item.code === 'appealData');
                result.appealsCollection = appealData && appealData.appealsCollection ? appealData.appealsCollection : 'appeals';
                const registersModelData = this.metaReglament.blocks.find(item => item.code === 'registersModel');
                if (registersModelData) {
                    if (registersModelData.licensesCollection) {
                        result.licensesCollection = registersModelData.licensesCollection;
                    }
                    if (registersModelData.registersCollection) {
                        result.registersCollection = registersModelData.registersCollection;
                    }
                }
            }
        }

        result.parentEntries = this.getAppealsCollection() + '.subservices';

        // Инициализация организации ответственной за предоставление услуги (ОГВ)
        if (subservice.responsibleOrgs && subservice.responsibleOrgs.length === 1) {
            result.responsibleOrganization = subservice.responsibleOrgs[0];
        } else {
            result.responsibleOrganizations = null;
        }

        // XSD по услуге
        if (subservice.xsd) {
            result.xsd = subservice.xsd;
            result.xsdData = {};
            result.xsdRequired = subservice.xsdLink ? subservice.xsdLink.required : true; // обязательность заполнения XSD (по умолчанию обязательно)
        }

        // Данные ФРГУ
        if (subservice.additionalInfo && subservice.additionalInfo.rgu && subservice.additionalInfo.rgu.length > 0) {
            result.rgu = subservice.additionalInfo.rgu;
        }

        // Инициализация статуса
        const startStatus = subservice.statusModel.status.find(item => item.isStart);
        result.status = this.statusService.initStatusData(startStatus);
        result.statusHistory = [result.status];

        // Вариант выдачи результата оказания услуги (в МФЦ, в ОГВ)
        if (subservice.issueResultForm) {
            result.issueResultForm = null;
            // Если вариант выдачи определен в услуге
            if (subservice.issueResultForm !== 'anyUnit') {
                result.issueResultForm = subservice.issueResultForm;
            }
        }
        this.initSpecialDataInAppealSubservice(subservice, result);

        return result;
    }

    public initSpecialDataInAppealSubservice(subservice, result) {
        switch (subservice.version) {
            case 'knd':
                this.initKndSpecialDataInAppealSubservice(subservice, result);
                break;
            default:
                break;
        }
    }

    public initKndSpecialDataInAppealSubservice(subservice, result) {
        result.entities = [];
        result.sendToErp = !!subservice.sendToErp;
        result.sendToZkh = !!subservice.sendToZkh;
        result.kndInfo = this.processingKndData(subservice);
    }

    public processingKndData(subservice) {
        const kndInfo: any = {};
        if (subservice.kndInfo) {
            if (subservice.kndInfo.kndTypeCode) {
                const code = subservice.kndInfo.kndTypeCode;

                const kndTypeDict = this.storage.getItem('nsiErpKnmTypes');
                const typeItem = kndTypeDict.filter(type => type.code === code)[0];
                if (typeItem) {
                    kndInfo.kndType = { code: code, name: typeItem.name };
                }
            }
            if (subservice.kndInfo.kndFormCode) {
                const code = subservice.kndInfo.kndFormCode;
                const kndFormDict = this.storage.getItem('nsiErpKnmForms');
                const formItem = kndFormDict.filter(form => form.code === code)[0];
                if (formItem) {
                    kndInfo.kndForm = { code: code, name: formItem.name };
                }
            }
            // Виды КНД
            // if (subservice.kndInfo.kndKindCode) {
            //     kndInfo.kndKind = {code: subservice.kndInfo.kndKindCode, name: subservice.kndInfo.kndKindName};
            // }
            kndInfo.kndKinds = [];
            if (subservice.kndInfo.kndKinds && subservice.kndInfo.kndKinds.length === 1) {
                kndInfo.kndKinds = [{ _id: subservice.kndInfo.kndKinds[0]._id, code: subservice.kndInfo.kndKinds[0].code, name: subservice.kndInfo.kndKinds[0].name }];
            }

            if (subservice.kndInfo.nsiZkhKnmKinds_65) {
                kndInfo.nsiZkhKnmKinds_65 = subservice.kndInfo.nsiZkhKnmKinds_65;
            }

            if (subservice.kndInfo.basisKnm) {
                kndInfo.basisKnm = subservice.kndInfo.basisKnm;
            }

            if (subservice.kndInfo.typeFederalLaw) {
                kndInfo.typeFederalLaw = subservice.kndInfo.typeFederalLaw;
            }
            if (subservice.kndInfo.npa) {
                kndInfo.npa = subservice.kndInfo.npa;
            }
        }

        return kndInfo;
    }

    /**
     * Обработка дел для выставления дополнительных свойств дела: просрочен, истекает и т.д.
     * @param appeals - массив дел
     */
    public processAppealsProperties(appeals) {
        appeals.forEach(appeal => {
            if (!appeal.subservice) {
                return;
            }
            delete appeal.notify;
            // Определение статусов "Просрочено", "Истекает"
            let isExpiredProcess = false; // флаг того что период оказания хотя бы по одной услуги дела истек
            let isExpireProcess = false; // флаг того, что период оказания хотя бы одной услуги дела истекат
            let isExpiredIssue = false; // флаг того, что время хранения результата хотя бы по одной услуге истек
            if (appeal.subservice.datePlaneFinish && (appeal.subservice.status.code === 'process' || (appeal.subservice.status.mainCode && appeal.subservice.status.mainCode === 'process'))) {
                if (moment(appeal.subservice.datePlaneFinish) < moment().startOf('day')) {
                    isExpiredProcess = true;
                } else if (moment(appeal.subservice.datePlaneFinish) > moment().startOf('day') && moment(appeal.subservice.datePlaneFinish) < moment().add(2, 'd').endOf('day')) {
                    isExpireProcess = true;
                }
            }
            if (appeal.subservice.dateMaxIssue && (appeal.subservice.status.code === 'beforeIssued' || appeal.subservice.status.code === 'rejected')) {
                if (moment(appeal.subservice.dateMaxIssue) < moment().startOf('day')) {
                    isExpiredIssue = true;
                }
            }
            if (isExpiredProcess || isExpireProcess || isExpiredIssue) {
                appeal.notify = [];
                if (isExpiredProcess) {
                    appeal.notify.push({
                        icon: null,
                        theme: 'bg-danger',
                        text: 'Просрочено время обработки',
                    });
                }

                if (isExpireProcess) {
                    appeal.notify.push({
                        icon: null,
                        theme: 'bg-orange',
                        text: 'Истекает время обработки',
                    });
                }
                if (isExpiredIssue) {
                    appeal.notify.push({
                        icon: null,
                        theme: 'bg-pink',
                        text: 'Истекает время хранения',
                    });
                }
            }

            if (appeal.isHavingResult) {
                if (!appeal.notify) {
                    appeal.notify = [];
                }
                appeal.notify.push({
                    icon: 'icon-bookmark2',
                    theme: 'text-primary-600',
                    text: 'Результаты запросов в деле',
                });
            }

            // Расчет процента прогресса дела от даты регистрации дела
            appeal.progressInProcent = 0; // значение по умолчанию
            if (appeal.dateFinish || appeal.dateReject || appeal.status.code === 'rejectedReceiptDocs' || appeal.dateAnnul || appeal.dateConsultation) {
                appeal.progressInProcent = 100;
            } else if (appeal.datePlaneFinish) {
                const termFinish = Math.ceil((new Date(appeal.datePlaneFinish).getTime() - new Date(appeal.dateCreation).getTime()) / 1000 / 60 / 60 / 24);
                const calcValue = (termFinish - Math.ceil(((new Date(appeal.datePlaneFinish).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24))) / termFinish * 100;
                appeal.progressInProcent = calcValue > 100 ? 100 : calcValue;
            }
        });

        return appeals;
    }

    public refreshAppealStatusData(status, service) {
        let mainAction = null;
        let actions;
        const statusGuid = status.isSubstatus ? status.mainStatusGuid : status.guid;
        // Объект текущего статуса дела
        this.appealStatus = service.statusModel.status.find(item => item.guid === statusGuid);
        if (status.isSubstatus) {
            // Объект подстатуса дела
            this.appealSubstatus = this.appealStatus.subStatuses.find(item => item.guid === status.guid);
            actions = this.appealSubstatus.actions;
            mainAction = actions.find(item => item.isMain);
            if (!mainAction) {
                mainAction = actions[0];
            }
            if (this.appealSubstatus.canFinish) {
                actions = actions.concat(this.appealStatus.actions.filter(item => !item.isStartSubstatus));
            }
        } else {
            actions = this.appealStatus.actions;
        }
        this.appealActions = actions;

        if (!mainAction) {
            mainAction = actions.find(item => item.isMain);
        }

        if (!mainAction) {
            mainAction = actions[0];
        }
        if (mainAction) {
            this.activeAppealAction = mainAction;
        }
    }

    public getStatusProperty(code, property, statuses = null) {
        statuses = statuses ? statuses : this.statuses;
        if (code) {
            const status = statuses.find(item => item.code === code);
            if (status) {
                return status[property];
            }
        }

        return '';
    }

    public calculateProgressBar(appeals) {
        appeals.forEach(appeal => {
            if (appeal.dateFinish || appeal.dateAnnul || appeal.dateConsultation) {
                appeal.progressInProcent = 100;
            } else if (appeal.datePlaneFinish) {
                const termFinish = Math.ceil((new Date(appeal.datePlaneFinish).getTime() - new Date(appeal.dateCreation).getTime()) / 1000 / 60 / 60 / 24);
                const calcValue = (termFinish - Math.ceil(((new Date(appeal.datePlaneFinish).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24))) / termFinish * 100;
                appeal.progressInProcent = calcValue > 100 ? 100 : calcValue;
            } else {
                appeal.progressInProcent = 0;
            }
        });

        return appeals;
    }

    /**
     * Цвет для label статусов
     * @param code
     * @param additional - приставка к цвету, например text-
     */
    public getColorForLabelStatuses(code, additional?) {
        let classes = this.getStatusProperty(code, 'background');

        if (additional) {
            classes = classes + ' ' + additional + classes.slice(2);
        }

        return classes;
    }

    /**
     * Определение статуса согласия на обработку персональных данных для клиента
     * @param appealStatus
     * @param param - параметр, который нужно вернуть
     * @returns {any}
     */
    public getAppealStatusColor(appealStatus, param?) {
        const result = this.statuses.find(element => {
            return element.code === appealStatus;
        });

        return param && result ? result[param] : result;
    }

    /**
     * Запрет на редактиррование дела с данными статусами (блокируем кнопки сохранения и удаления)
     */
    public checkCanChangeAppealByStatus() {
        return this.finishStatuses.indexOf(this.appealStatus.code) === -1;
    }

    /**
     * Получение пользователей для контроля над делом
     */
    public getUsersForControl() {
        return this.rest.search('users')
            .then(users => {
                    users = this.utility.getDataForProperties(users, ['id', 'login', 'name']);

                    return Promise.resolve(users);
                }, error => {
                    return Promise.reject(error);
                },
            );
    }

    public getAppealPayments() {
        if (!this.subservice.payments) {
            return;
        }

        const payments = [];
        this.subservice.payments.forEach(subservicePayment => {
            if (this.appeal.subservice.variant && subservicePayment.variantGuids.indexOf(this.appeal.subservice.variant.guid) !== -1
                || !this.appeal.subservice.variant && subservicePayment.variantGuids.length === 0) {
                const foundSubservicePayment = payments.find(item => item.code === subservicePayment.code);
                if (!foundSubservicePayment) {
                    const paymentForAdd = cloneDeep(subservicePayment);
                    delete paymentForAdd.variantGuids;
                    delete paymentForAdd.unitId;
                    payments.push(paymentForAdd);
                }
            }
        });

        if (payments.length) {
            this.appealPayments = cloneDeep(payments);
        }
    }

    /**
     * Получение списка участников, согласных на опрос
     * @param appealSubservice - услуга дела
     * @returns {any}
     */
    public getAppealSubserviceObjectsAgreeMkguInterview(appealSubservice) {
        const objectGuids = [];
        appealSubservice.objects.forEach(object => {
            objectGuids.push(object.guid);
            if (object.representative) {
                objectGuids.push(object.representative.guid);
            }
        });
        console.log('objectGuids', objectGuids);
        if (objectGuids.length > 0) {
            return this.appeal.objects.filter(item => item.agreeMkguInterview && objectGuids.indexOf(item.guid) !== -1);
        }

        return [];
    }

    public completeCamundaTask(taskId, mainId, guid, params) {
        const url = Config.server + Config.api;

        return this.httpService.post(
            `${url}camunda/task/${taskId}/complete?mainId=${mainId}&guid=${guid}`
            , params);
    }

    /**
     * КНМ активности процесса камунды
     * @param id - ID процесса
     * @returns {Promise<object>}
     */
    public checkCamundaProcessActivity(id = null) {
        if (!id && this.appeal.subservice.camundaProcessInfo) {
            id = this.appeal.subservice.camundaProcessInfo.id;
        }
        if (id) {
            const url = Config.server + Config.api + 'camunda/process-instance/' + id;

            return this.httpService.get(url).then(
                (result: any) => {
                    if (result.status === 'NOT_FOUND') {
                        this.isActiveProcessInAppeal = false;

                        return Promise.resolve(false);
                    } else {
                        this.isActiveProcessInAppeal = true;

                        return Promise.resolve(true);
                    }
                },
                    error => {
                    if (error.status === 404 && error.error.status === 'NOT_FOUND') {
                        this.isActiveProcessInAppeal = false;

                        return Promise.resolve(false);
                    } else {
                        return Promise.reject(false);
                    }
                },
            );
        } else {
            return Promise.reject(false);
        }
    }

    public updateAppealCamundaProcessTasks() {
        const params = [{
            andSubConditions: [
                {
                    field: 'mainId',
                    operator: 'eq',
                    value: this.appeal._id,
                },
                {
                    field: 'guid',
                    operator: 'eq',
                    value: this.appeal.subservice.guid,
                },
            ],
        }];

        return this.rest.search('camundaBusinessInfo', { search: { search: params } }).then(items => {
            if (items.length > 0) {
                this.processTasks = items[0].tasks.map(item => ({ ...item, camundaBusinessInfoId: items[0]._id }));
            }

            return Promise.resolve(true);
        });
    }

    public getCamundaTasks(camunda) {
        const arr = chain(camunda)
            .values()
            .map(item => ({
                ...item,
                startDate: moment(item.created).format('DD.MM.YYYY HH:mm'),
                finishDate: moment(item.created).format('DD.MM.YYYY'),
            }))
            .value();

        return orderBy(arr, ['created'], ['desc']);
    }

    public completeTaskInAppealProcess(task) {
        this.appeal.subservice.camunda.tasks[task.id].status = 'COMPLETED';

        return this.appealSaveService.saveAppeal();
    }

    public getAppealsCollection() {
        if (this.metaReglament) {
            if (this.metaReglament.isOldVersion && this.metaReglament.appealsCollection) {
                return this.metaReglament.appealsCollection;
            } else {
                const block = this.metaReglament.blocks.find(item => item.code === 'appealData');
                if (block && block.appealsCollection) {
                    return block.appealsCollection;
                }
            }
        }

        return 'appeals';
    }

    public correctCheckLists() {
        if (this.subservice.checkLists && this.subservice.checkLists.length > 0) {
            this.appeal.objects.forEach(subject => {
                if (subject.objects && subject.objects.length > 0) {
                    subject.objects.forEach(object => {
                        const objectId = object.reestrId;
                        if (this.appeal.checkLists) {
                            const find = this.appeal.checkLists.find(item => item.objectId === objectId);
                            if (!find) {
                                this.createCheckList(subject.guid, object.guid);
                            }
                        } else {
                            this.createCheckList(subject.guid, object.guid);
                        }
                    });
                }
            });
        }
    }

    /**
     * Добавление чек листа для объекта
     * @param subjectGuid
     * @param objectGuid
     */
    public createCheckList(subjectGuid, objectGuid) {
        if (this.subservice.checkLists) {
            if (!this.appeal.checkLists) {
                this.appeal.checkLists = [];
            }
            const subject = this.appeal.objects.find(item => item.guid === subjectGuid);
            const object = subject.objects.find(item => item.guid === objectGuid);
            const npaLink = this.subservice.checkLists.length > 0
                ? this.subservice.checkLists[0].npaLink
                : null;

            const objectCheckList = {
                guid: CommonUtilities.GenerateGuid(),
                subjectId: subject.specialTypeId === 'ulApplicant' ? subject.data.organization.reestrId : subject.data.person.reestrId,
                subjectGuid: subject.guid,
                subjectAuid: subject.specialTypeId === 'ulApplicant' ? subject.data.organization.auid : subject.data.person.auid,
                objectId: object.reestrId,
                objectGuid: object.guid,
                objectAuid: object.auid,
                questions: [],
                npaLink: npaLink,
            };
            let questionIndex = 1;
            this.subservice.checkLists.forEach(checkList => {
                if (checkList.questions && checkList.questions.length > 0) {
                    checkList.questions.forEach(question => {
                        const find = objectCheckList.questions.find(item => item.id === question.id);
                        if (!find) {
                            objectCheckList.questions.push({
                                auid: questionIndex,
                                id: question.id,
                                text: question.name,
                                answerYes: question.answerYes,
                                answerNo: question.answerNo,
                                answerNotConcidered: question.answerNotConcidered,
                                mandatoryReqs: question.mandatoryReqs,
                                nPA: question.nPA,
                            });
                            questionIndex++;
                        }
                    });
                }
            });
            this.appeal.checkLists.push(objectCheckList);
        }
    }

    public deleteCheckList(objectId) {
        if (this.appeal.checkLists) {
            const findIndex = this.appeal.checkLists.find(item => item.objectId === objectId);
            if (findIndex !== -1) {
                this.appeal.checkLists.splice(findIndex, 1);
            }
        }
    }

    public initAppealLinksData() {
        this.appealLinksData = {};

        if (this.appeal.eventId) {
            this.rest.find('events', this.appeal.eventId).then((event: any) => {
                if (event) {
                    this.appealLinksData['event'] = event;
                    const promises = [];
                    const linksItems = [];
                    if (event.inspectionProgramId) {
                        promises.push(this.rest.find('inspectionProgramGsn', event.inspectionProgramId));
                        linksItems.push('inspectionProgramGsn');
                    }
                    if (event.supervisoryCaseId) {
                        promises.push(this.rest.find('supervisoryCase', event.supervisoryCaseId));
                        linksItems.push('supervisoryCase');
                    }
                    if (event.inspectionPlansId) {
                        promises.push(this.rest.find('inspectionPlans', event.inspectionPlansId));
                        linksItems.push('inspectionPlans');
                    }
                    Promise.all(promises).then(result => {
                        linksItems.forEach((link, idx) => {
                            this.appealLinksData[link] = result[idx];
                        });
                    });
                }
            });
        }

        if (this.appeal._id && this.appeal.parentEntries === 'financialControlAppeals') {
            this.rest.findAll('events', {
                search: {
                    search: [{
                        orSubConditions: [
                            { field: 'fromAppealId', operator: 'eq', value: this.appeal._id },
                            { field: 'appealIds', operator: 'in', value: [this.appeal._id] },
                        ],
                    }],
                },
                size: 500,
            }).then((events: any[]) => {
                const eventWithInspectionPlan = events.find(item => item.inspectionPlansId && item.planProjectsGuid);

                if (eventWithInspectionPlan) {
                    this.rest.find('inspectionPlans', eventWithInspectionPlan.inspectionPlansId).then((plan: any) => {
                        if (plan) {
                            this.appealLinksData['inspectionPlan'] = plan;

                            if (Array.isArray(plan.planProjects) && plan.planProjects.length > 0) {
                                this.appealLinksData['planProject'] = plan.planProjects.find(item => item.guid === eventWithInspectionPlan.planProjectsGuid);
                            }
                        }
                    });
                }

                this.appealLinksData['events'] = events;
            });
        }
    }

    public getElementTitle(element, defaultValue) {
        if (this.metaReglament) {
            if (!this.metaReglament.isOldVersion) {
                const appealData = this.metaReglament.blocks.find(item => item.code === 'appealData');
                if (appealData && appealData.appealTitles) {
                    return appealData.appealTitles[element] ? appealData.appealTitles[element] : defaultValue;
                }
            } else if (this.metaReglament.appealTitles) {
                return this.metaReglament.appealTitles[element] ? this.metaReglament.appealTitles[element] : defaultValue;
            }
        }

        return defaultValue;
    }

    public addElementGuidToMainElement(element, elementType) {
        if (this.appeal.subservice.mainElement && this.appeal.subservice.mainElement[elementType]) {
            this.appeal.subservice.mainElement[elementType].push(element.guid);
        }
    }

    public async updateAppealAfterChangeAppealDataOnServer(camundaBusinessInfoId, collection) {
        const appeal: any = await this.rest.find(collection, this.appeal._id);
        delete appeal.subjects;
        delete appeal.objects;
        delete appeal.documents;
        this.appeal = Object.assign(this.appeal, appeal);
        if (this.appeal.subservice.guid !== appeal.subservice.guid) {
            return;
        }

        this.appeal.subservice = Object.assign(this.appeal.subservice, appeal.subservice);
        this.appealSaveService.baseAppeal = cloneDeep(this.appeal);

        return await this.rest.update('camundaBusinessInfo', {_id: camundaBusinessInfoId, isEntryStatusChanged: false});
    }

    public async saveHistoryItem(elementData, isChangeStatus = false) {
        if (!(this.subservice.appealData && this.subservice.appealData.saveOperationsHistory || this.subservice.registersModel && this.subservice.registersModel.saveOperationsHistoryInLicense)) {
            return;
        }

        let collection;
        let historyData;
        let needUpdateLicense = false;
        if (this.subservice.registersModel && this.subservice.registersModel.saveOperationsHistoryInLicense && this.appeal.subservice.mainElement.licenseId) {
            // Данные будут сохраняться в лицензию
            collection = this.appeal.subservice.licensesCollection;
            const license: any = await this.rest.find(collection, this.appeal.subservice.mainElement.licenseId, false, 'historyData');
            historyData = license.historyData ? license.historyData : [];
            if (this.appeal.historyData && this.appeal.historyData.length > 0) {
                const notAdded = this.appeal.historyData.filter(item => !item.isSavedInLicense);
                if (notAdded.length > 0) {
                    historyData = historyData.concat(notAdded);
                }
                this.appeal.historyData = this.appeal.historyData.map(item => ({...item, isSavedInLicense: true}));
            }
            needUpdateLicense = true;
        } else {
            // Данные будут сохраняться в дело
            collection = this.appeal.parentEntries;
            if (!this.appeal.historyData) {
                this.appeal.historyData = [];
            }
            historyData = this.appeal.historyData;
            elementData = this.subservice.statusModel.status.find(item => item.code === elementData.code);
        }
        let historyText;
        const statusActions = {process: 'Зарегистрировано заявление', complete: 'Завершена работа с заявлением'};
        if (isChangeStatus) {
            historyText = elementData.historyText ? elementData.historyText : statusActions[elementData.code];
            if (this.appeal.subservice.title) {
                historyText += ' «' + this.appeal.subservice.title + '»';
            }
        } else {
            historyText = elementData.historyText ? elementData.historyText : elementData.eventName;
        }
        const user = this.storage.getItem('user');
        historyData.push({
            description: historyText,
            dateViewed: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
            userViewed: {
                name: user.name ? user.name : '',
                position: user.position ? user.position : '',
            },
        });
        if (needUpdateLicense) {
            await this.rest.update(collection, {_id: this.appeal.subservice.mainElement.licenseId, historyData: historyData});
        }
    }

    public initRoutingPaths(applicationPath: string): void {
        const routing = this.applicationsService.getApplicationPropertyByApplicationPath(applicationPath, 'routing');
        let registersPath = 'registers';
        let appealsPath = 'appeals';

        if (this.metaReglament && Array.isArray(routing)) {
            const findRegistryData = routing.find(item => item.isRegistryBlock && item.metaReglamentCode === this.metaReglament.code);
            const findAppealData = routing.find(item => item.isAppealsBlock && item.metaReglamentCode === this.metaReglament.code);

            if (findRegistryData && findRegistryData.path) {
                registersPath = findRegistryData.path;
            }

            if (findAppealData && findAppealData.path) {
                appealsPath = findAppealData.path;
            }
        }

        this.registersPath = registersPath;
        this.appealsPath = appealsPath;
    }
}

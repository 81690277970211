import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AccessService,
    FilesService,
    HttpService,
    RestService,
    SelectionService,
    StorageService,
    ToasterService,
    TranslateService,
} from '@evolenta/core';
import { PrintingService } from '@evolenta/printing';
import { NgProgress } from 'ngx-progressbar';
import { PrintFormsService } from '../../../common/services/print/print-forms.service';
import { AppealService } from './appeal.service';
import { DocumentService } from './components/documents/document.service';
import { AppealValidateService } from './appeal-validate.service';
import { AppealSaveService } from './appeal-save.service';
import { AppealStatusService } from './appeal-status.service';
import { ReasonModalComponent } from './components/objects/special-types/reason-modal/reason-modal.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { AppealSubservicesComponent } from './components/subservices/appeal-subservices.component';
import { ObjectsComponent } from './components/objects/objects.component';
import { AppealSubjectsComponent } from './components/appeal-subjects/appeal-subjects.component';
import { AppealSubservicesService } from './appeal-subservices.service';
import { ReestrModalComponent } from './components/objects/special-types/reestr-modal/reestr-modal.component';
import { Permission } from '../../../common/services/permission';
import { TicketService } from '../../../common/services/ticket.service';
import { QuickRequestsService } from '../../../common/services/quick-requests.service';
import { AppealRequirementsService } from './appeal-requirements.service';
import { AppealProcessService } from './components/process/appeal-process.service';
import { ProcessService } from '../../processes/process.service';
import { EventsService } from '../../events/events/events.service';
import { Config } from '../../../common/services/config';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash-es';
import { RsoService } from '../../../common/services/rso.service';
import { FilesQueueService } from '../../../common/services/filesQueue.service';
import { TasksService } from '../../tasks/tasks/tasks.service';
import { ErrorLoggingService } from '../error-logging.service';
import { SUBSERVICES_ARCHIVE_COLLECTION, SUBSERVICES_COLLECTION } from '../../../common/constants';

@Component({
    selector: 'appeal',
    templateUrl: 'appeal-edit.component.html',
    styleUrls: [
        '../../element-edit.css',
    ],
})
export class AppealEditComponent implements OnInit, OnDestroy {
    public TABS = {
        ACTIVE_TASKS: 'active-tasks',
        COMPLETED_TASKS: 'completed-tasks',
        SUBSERVICES: 'subservices',
        OBJECTS: 'objects',
        SUBJECTS: 'subjects',
        PROCESS: 'process',
        REQUIREMENTS: 'requirements',
        DOCUMENTS: 'documents',
        HISTORY: 'history',
        ENVELOPES: 'envelopes',
    };

    public STATUSES = {
        DRAFT: 'draft',
        ARCHIVE: 'archive',
    };

    public MODE = {
        EDIT: 'edit',
        CREATE_ON_SUBSERVICE: 'createOnSubservice',
        CREATE_ON_COMPLEX_SUBSERVICE: 'createOnComplexSubservice',
    };

    public mode; // режим работы: редактирование, создание на основе услуги, создание на основе комплексной услуги
    public appeal: any = {}; // обрабатываемое дело
    public complexSubservice = null; // комплексная услуга, на основе которой формируется дело
    public subservice: any = {}; // услуга на основе которых формируется дело

    public appealSubservicesData = []; // описательный массив для услуг (для хранения единоразово запрашиваемой с сервера информации)

    public printForms = []; // Массив печатных форм
    public printFormsWithoutDocs = []; // Массив печатных форм без тех, которые привязаны к видам документов дела

    public historyItems = null; // Массив элементов истории по делу
    public envelopes = null; // Конверты в деле
    public messages = null; // Сообщения в деле

    public activeTab = this.TABS.SUBSERVICES; // Активная вкладка
    public currentOrganization = this.storage.getItem('currentOrganization'); // Текущая активная организация

    public appealProcessingType; // Вид операции с делом: save - сохранение, changeStatus - выполнение действия (изменение статуса)
    public appealProcessingSubservice; // Услуга дела, в которой в данный момент осуществляется переход на другой статус
    public processingAction; // Действие, которое выполняется для перевода дела (услуги) в другой статус
    public actions; // массив доступных действий для дела
    public activeAction; // активное действие
    public isRollBack = false; // режим отката статуса
    public reasonItem; // выбранный элемент доп действия для перехода на другой статус
    public reasonItems; // массив элементов для отображения в диалоговом окне при выполнении специальных действий (отказ, аннулирование и т.д.)
    public reasonFields; // массив описаний текстовых полей
    public reasonTitle; // заголовок модального окна со спец действиями для action-а дела
    public parentReason; // значение родительского элемента в случае наличия дочерних действий
    public reasonReturnOnlyCode = false; // возвращать только код из объекта элементов
    public refetchInterval;
    @ViewChild('reasonModal', { static: false }) public reasonModalComponent: ReasonModalComponent; // модальное окно для выбора причины отказа, аннулирования и т.д.

    // Переменные-компоненты, для доступа к внутренним функциям (КНМ сохранения)
    @ViewChild('appealSubservices', {static: false}) public appealSubservicesComponent: AppealSubservicesComponent; // услуги дела
    @ViewChild('appealSubjects', {static: false}) public appealSubjectsComponent: AppealSubjectsComponent; // объекты дела
    @ViewChild('appealObjects', {static: false}) public appealObjectsComponent: ObjectsComponent; // объекты дела
    @ViewChild('appealDocuments', {static: false}) public appealDocumentsComponent: DocumentsComponent; // документы дела
    @ViewChild('reestrModal', {static: false}) public reestrModal: ReestrModalComponent; // модальное окно для выбора соответствия объекта дела и клиента в реестре клиентов

    public permissions; // набор прав доступа к элементам системы

    public currentTicket = null; // текущий талон ЭО
    public appealSubserviceActions = [];
    public moduleBaseUrl;

    public address = null;

    public isProcessingCleanupAndMoveToArchive = false; // процесс очистки персональных данных и переноса дела в архив из преархива
    public isProcessingRollbackFromPreArchive = false; // процесс переноса дела из преархива в основную коллекцию

    public localizations;
    public initialized;
    public params;
    public isDisabledByRso = false;

    private processTasksEntryStatusChangedSubscription: Subscription = null;
    private processTasksPollTriggeredSubscription: Subscription = null;

    private timeoutIds = [];

    private intervalIds = [];

    public constructor(
        public validateService: AppealValidateService,
        public appealSaveService: AppealSaveService,
        public accessService: AccessService,
        public appealProcessService: AppealProcessService,
        public appealService: AppealService,
        private route: ActivatedRoute,
        private appealSubservicesService: AppealSubservicesService,
        private storage: StorageService,
        private printFormService: PrintFormsService,
        private printingService: PrintingService,
        private progressService: NgProgress,
        private toaster: ToasterService,
        private documentService: DocumentService,
        private filesService: FilesService,
        private rest: RestService,
        private appealStatusService: AppealStatusService,
        private ticketService: TicketService,
        private quickRequestsService: QuickRequestsService,
        private appealRequirementsService: AppealRequirementsService,
        private selectionService: SelectionService,
        private processService: ProcessService,
        private eventsService: EventsService,
        private httpService: HttpService,
        private translate: TranslateService,
        private errorLoggingService: ErrorLoggingService,
        private router: Router,
        private rsoService: RsoService,
        private filesQueueService: FilesQueueService,
        private tasksService: TasksService,
    ) {
    }

    /**
     * Инициализация компонента: обработка данных, полученных по resolve
     */
    public async ngOnInit() {
        this._loadTranslations();
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });

        this.permissions = Permission; // описание всех доступных прав доступа
        // Определение режима работы: редактирование, создание на основе услуги, создание на основе комплексной услуги
        this.route.params.subscribe((params: any) => {
            this.params = params;
            if (params.appealId) {
                this.mode = this.MODE.EDIT;
            } else if (params.subserviceId) {
                this.mode = this.MODE.CREATE_ON_SUBSERVICE;
            } else if (params.complexSubserviceId) {
                this.mode = this.MODE.CREATE_ON_COMPLEX_SUBSERVICE;
            }
        });

        if (this.selectionService.isProcessSelect) {
            await this._processSelectQuestionsForCheckList();

            return;
        }

        if (this.appealService.isProcessSetupAppealSubservices) {
            // Режим повторного выбора услуг в состав комплексного дела
            this.appeal = this.appealService.appeal;

            if (this.appeal.subservices) {
                this.appeal.subservice = this.appeal.subservices[0];
                delete this.appeal.subservices;
            }
            this.subservice = this.appealService.subservice;
            this.complexSubservice = this.appealService.complexSubservice;
            // TODO нужна ли комплексная услуга?
            // this._initComplexAppealMode();

            this.isDisabledByRso = this.rsoService.canNotEditAppeal(this.appeal);
            this.filesQueueService.open();

            return;
        }

        this._baseInitServiceData(); // Очистка данных сервиса (чтобы не было хвостов от работы с другими делами)
        // Получение и обработка первоначальных данных
        this.route.data.subscribe(
            async (response: any) => {
                // Удаление базового дела
                this.storage.removeItem('baseAppeal');

                if (this.mode === 'createOnComplexSubservice') {
                    /**
                     * Создание дела на основе комплексной услуги
                     */
                    // Корректировка комплексной услуги в соответствии с текущей организацией
                    this.complexSubservice = this.appealService.correctComplexSubserviceByCurrentUnit(response.resolves);
                    // Базовая инициализация дела
                    this.appeal = this.appealService.initAppeal();
                    this.appealService.appeal = this.appeal;

                    // Инициализация данных в сервисах для работы с сервисом
                    await this._initServicesData();
                } else if (this.mode === 'createOnSubservice') {
                    // Если дело формируется на основе копируемого дела
                    if (this.appealService.appealCopy) {
                        this.appeal = _.cloneDeep(this.appealService.appealCopy); // сформированнное при копировании дело
                        this.subservice = _.cloneDeep(this.appealService.subserviceFromAppealCopy); // услуги, по которым сформировано дело
                        // Очистка данных сервиса
                        this.appealService.appealCopy = null;
                        this.appealService.subserviceFromAppealCopy = null;
                    } else {
                        /**
                         * Создание дела на основе отдельной услуги
                         */
                        // Корректировка услуги в соответствии с текущей организацией
                        const subservice = this.appealService.correctSubserviceByCurrentUnit(response.resolves);

                        if (this.subservice && Object.keys(this.subservice).length) {
                            throw new Error(`Попытка переназначить subservice с ${ this.subservice.id } на ${ subservice.id }`);
                        }

                        this.subservice = subservice;
                        this.appealService.subservice = this.subservice;

                        // Базовая инициализация дела
                        this.appeal = this.appealService.initAppeal();
                        this.appealService.appeal = this.appeal;
                        // Добавление каркаса описания объектов внутри услуги
                        const initiatedSubservice = await this.appealService.initSubserviceInAppeal(subservice);

                        if (this.appeal.subservice && Object.keys(this.appeal.subservice).length) {
                            throw new Error(`Попытка переназначить subservice с ${ this.appeal.subservice.id } на ${ initiatedSubservice.id }`);
                        }

                        this.appeal.subservice = initiatedSubservice;
                    }
                    // Инициализация данных в сервисах для работы с сервисом
                    await this._initServicesData();

                    // Получение печатных форм
                    if (this.accessService.hasAccess([this.permissions.Print_Forms])) {
                        await this._updateListPrintForms(true);
                    }
                } else if (this.mode === this.MODE.EDIT) {
                    /**
                     * Редактирование дела
                     */
                    // Получение объекта дела
                    this.appeal = response.resolves.appeal;
                    this.appealService.appeal = this.appeal;
                    if (this.appeal.status.code !== this.STATUSES.DRAFT) {
                        // @ts-ignore
                        this.activeTab = this.TABS.PROCESS;
                    }

                    this.storage.setItem('baseAppeal', this.appeal);
                    this.appealSubservicesData.push({id: response.resolves.subservice._id});
                    const correctedSubservice = this.appealService.correctSubserviceByCurrentUnit(response.resolves.subservice);
                    if (this.subservice && Object.keys(this.subservice).length) {
                        throw new Error(`Попытка переназначить subservice с ${ this.appeal.subservice.id } на ${ correctedSubservice.id }`);
                    }

                    this.subservice = correctedSubservice;

                    // Корректировка комплексной услуги в соответствии с текущей организацией
                    if (response.resolves.complexSubservice) {
                        this.complexSubservice = this.appealService.correctComplexSubserviceByCurrentUnit(response.resolves.complexSubservice);
                    }

                    // Инициализация данных в сервисах для работы с сервисом
                    await this._initServicesData();

                    // Получение печатных форм
                    if (this.accessService.hasAccess([this.permissions.Print_Forms])) {
                        await this._updateListPrintForms(true);
                    }

                    // Добавляем дату для уведомления о просрочке
                    if (this.appeal.datePlaneFinish && !this.appeal.datePlaneExpired) {
                        let dayExpired = 2;
                        if (Array.isArray(this.appeal.subservice.actualResultFinishNotificationPeriod)) {
                            const foundDate = this.appeal.subservice.actualResultFinishNotificationPeriod
                                .find(item => item.guid === this.appeal.subservice.variant.guid);

                            dayExpired = (foundDate && foundDate.actualResultFinishNotificationPeriod) ? foundDate.actualResultFinishNotificationPeriod : 2;
                        }
                        this.appeal.datePlaneExpired = moment(this.appeal.datePlaneFinish)
                            .add(-dayExpired, 'days')
                            .format('YYYY-MM-DDTHH:mm:ssZZ');

                        await this.appealSaveService.saveAppeal();
                    }

                    if (this.appealService.activeTabInAppealBeforeCreate) {
                        this.activeTab = this.appealService.activeTabInAppealBeforeCreate;
                        this.appealService.activeTabInAppealBeforeCreate = null;
                    }
                }

                // Инициализация данных для валидации дела
                this.validateService.appeal = this.appeal;
                this.validateService.subservice = this.subservice;

                // Добавление информации об обслуживаемом талоне в дело (при работе с пультом)
                if (this.ticketService.ticket && this.ticketService.ticket.id) {
                    if (!this.appeal.tickets) {
                        this.appeal.tickets = [];
                    }
                    const find = this.appeal.tickets.find(item => item.id === this.ticketService.ticket.id);
                    if (!find) {
                        this.currentTicket = {
                            id: this.ticketService.ticket._id,
                            externalId: this.ticketService.ticket.id,
                            seansId: this.ticketService.ticket.seansId,
                        };
                        this.appeal.tickets.push(this.currentTicket);
                    }
                }

                // Задание упрощенного режима работы с комплексным делом при приеме документов
                // this._initComplexAppealMode();

            },
            error => {
                console.log(error);
            },
        );
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'events',
                'appeals',
                'services',
                'organizations',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    public checkHasUserDocuments() {
        // return this.appeal.documents && this.appeal.documents.some(document => document.feedbackDocumentUnchecked);
        // TODO раскомментировать после переезда на кубер
        return false;
    }

    public canShowActiveTasksTab() {
        // Изменено для учета случая когда происходит откат транзакции
        // (например, по причине неудачи оптимистической блокировки) в БП на последней пользовательской задаче
        // с последующей выполненной служебной задачей перевода дела в статус завершено,
        // когда изменения фиксируются в Mongo вне БП транзакции,
        // при этом надо сохранить возможность попытаться завершить задачу повторно,
        // чтобы, например, повторно отправить итоговые статусы ЕПГУ/ЕЛК
        if (!this.rsoService.isRsoUser()) {
            return this.appeal._id && this.appeal.status.code !== this.STATUSES.DRAFT
                && (this.appealProcessService.isProcessActive || this.appealProcessService.hasAnyActiveProcess);
        }

        return this.appeal._id && this.appeal.status.code !== this.STATUSES.DRAFT && this.appealProcessService.isProcessActive;
    }

    public canShowCompletedTasksTab() {
        return this.appeal._id && this.appeal.status.code !== this.STATUSES.DRAFT && this.existCamundaProcess;
    }

    public canShowHistoryTab() {
        return this.appeal._id;
    }

    /**
     * Определение параметра упрощенного режима работы с комплексным делом при приеме документов (до регистрации дела)
     */
    /*private _initComplexAppealMode() {
        if (!(this.appeal.status.code === this.STATUSES.DRAFT && this.appeal.subservices.length > 1 && !this.appeal.hasOwnProperty('simplifyMode'))) {
            return;
        }

        const complexAppealSimplifiedMode = this.storage.getItem('complexAppealSimplifiedMode');
        if (complexAppealSimplifiedMode) {
            this.appeal.simplifyMode = complexAppealSimplifiedMode;
        }
    }*/

    public async _processSelectQuestionsForCheckList() {
        this.appeal = this.selectionService.transferObject;
        this.appealService.appeal = this.appeal;
        if (this.selectionService.additionalData) {
            this.subservice = this.selectionService.additionalData.subservice;
            if (this.selectionService.transferOperation === 'selectLicenses') {
                const license = this.selectionService.selectedItems[0];
                if (license) {
                    const mainElement = this.appeal.subservice.mainElement;
                    mainElement.license = license;
                    mainElement.licenseId = license._id;
                }
            } else if (this.appeal.checkLists) {
                const findCheckList = this.appeal.checkLists
                    .find(item => item.guid === this.selectionService.additionalData.checkList.guid);

                if (findCheckList) {
                    this.selectionService.selectedItems
                        .forEach(question => {
                            const find = findCheckList.questions
                                .find(item => item.id === question._id);
                            if (!find) {
                                findCheckList.questions.push({
                                    id: question._id,
                                    text: question.name,
                                    answerYes: question.answerYes,
                                    answerNo: question.answerNo,
                                    answerNotConcidered: question.answerNotConcidered,
                                    auid: findCheckList.questions.length,
                                    mandatoryReqs: question.mandatoryReqs,
                                    nPA: question.nPA,
                                });
                            }
                        });
                }
            }
        }

        this.selectionService.clearData();
        this.activeTab = this.TABS.REQUIREMENTS;

        await this._initServicesData();
    }

    /**
     * Выгрузить архив с документами дела
     */
    public async uploadDocuments() {
        try {
            await this.appealService.getAppealsFilesInZIP(this.appeal);
        } catch (error) {
            console.log('Ошибка выгрузки файлов', error);
            this.toaster.error(error.error && error.error.description ? error.error.description : error);
        }
    }

    /**
     * Очистка (обнуление) данных сервисов для работы с делом
     */
    private _baseInitServiceData() {
        this.appealService.clearData();
        this.appealStatusService.clearData();
        this.appealSubservicesService.clearData();
        this.documentService.clearData();
        this.validateService.clearData();
        this.quickRequestsService.clearData();
        this.appealRequirementsService.clearData();
    }

    /**
     * Инициализация данных сервисов
     */
    private async _initServicesData() {
        // Общие данные по делу: услуги, комплексные услуги
        this.appealService.appeal = this.appeal;
        this.appealService.subservice = this.subservice;
        this.appealService.allKnoUsers = null;
        await this.appealService.initAppealLinksData();

        // Сервис статусов дела и услуг внутри дела
        this.appealStatusService.initData(this.appeal, this.subservice, this.complexSubservice);
        this.actions = this.appealStatusService.initAppealActions();
        this.activeAction = this.appealStatusService.activeAction;

        // Информация о настройке объектов в услугах
        this.appealSubservicesService.initData(this.appeal, this.subservice);

        // Сервис документов
        this.documentService.initData(this.appeal, this.subservice);

        // Сервис сохранения данных
        this.appealSaveService.appeal = this.appeal;
        this.appealSaveService.reestrModal = this.reestrModal;
        this.appealSaveService.isProcessSavingAppeal = false;
        this.appealSaveService.moduleBaseUrl = this.moduleBaseUrl;

        // Определение режима работы с делом редактирование / создание
        this.appealSaveService.isCreateAppealMode = this.mode !== this.MODE.EDIT;

        // Сервис валидации дела
        this.validateService.appeal = this.appeal;
        this.validateService.subservice = this.subservice;

        // Получение платежей
        this.appealService.getAppealPayments();

        // Сервис работы с требованиями
        this.appealRequirementsService.appeal = this.appeal;
        this.appealRequirementsService.subservice = this.subservice;

        // Инициализация информации о процессах камунды
        await this._initProcessInfo();
    }

    /**
     * Инициализация задач процесса
     */
    private async _initProcessInfo() {
        // Инициализация данных
        this.appealProcessService.tasks = [];
        this.appealProcessService.activeTasks = [];
        this.appealProcessService.completedTasks = [];
        this.appealProcessService.isProcessUpdateTasks = false;
        this.appealProcessService.isAutoUpdateTasks = true;
        this.appealProcessService.camundaProcessId = null;
        this.appealProcessService.tasksData = {};

        if (!(this.subservice.camundaProcess && Object.keys(this.subservice.camundaProcess).length && this.appeal.subservice.camundaProcessInfo)) {
            this.appealProcessService.isProcessActive = false;
            await this._initTabAfterGetProcessInfo();

            return;
        }

        if (this.appeal.status.code === this.STATUSES.DRAFT) {
            if (this.appeal.source === 'epgu') {     // CIT-1127
                const hadEpguAppealProcessStatus = this.appeal.subservice.statusHistory.some(status => status.code === 'process');
                if (hadEpguAppealProcessStatus) {
                    const errorMessage = 'Дело, созданное в ЕПГУ, имеет статус дела \'Черновик\' и содержит в истории статусов subservice \'В работе\'';
                    this.toaster.error(errorMessage);
                    await this.errorLoggingService.log(this.errorLoggingService.APPEAL, new Error(errorMessage));
                }
            }

            return;
        }

        if (this.appeal.subservice.status.code === 'complete') {
            const tasksData = await this.processService
                .updateCamundaProcessTasks(
                    this.appeal._id,
                    this.appeal.subservice.guid,
                    this.appeal.subservice.camundaProcessInfo.camundaBusinessInfoId,
                );

            this.updateCamundaProcessTasks(tasksData);
            await this._initTabAfterGetProcessInfo();

            return;
        }

        const camundaProcessId = this.appeal.subservice.camundaProcessInfo.id;

        this.appealProcessService.isProcessActive = await this.processService.checkProcessIsActive(camundaProcessId);
        this.appealProcessService.hasAnyActiveProcess = this.appealProcessService.isProcessActive;

        await this._updateTasksList(
            this.appeal._id,
            this.appeal.subservice.guid,
            this.appeal.subservice.camundaProcessInfo.camundaBusinessInfoId);

        if (!this.appealProcessService.isProcessActive) {
            this.appealProcessService.stopProcessTasksPolling();

            if (this.appealProcessService.activeTasks.length === 0) {
                if (this.appealProcessService.updateTasksInterval) {
                    clearInterval(this.appealProcessService.updateTasksInterval);
                }

                this.appealProcessService.isProcessUpdateTasks = false;
            }

            return;
        }

        this.appealProcessService.startProcessTasksPolling();

        // подписываемся на триггер обновления списка задач
        this.processTasksPollTriggeredSubscription = this.appealProcessService.processTasksPollTriggered
            .subscribe(async () => {
                const tasksData = await this.processService
                    .updateCamundaProcessTasks(
                        this.appeal._id,
                        this.appeal.subservice.guid,
                        this.appeal.subservice.camundaProcessInfo.camundaBusinessInfoId,
                    );
                this.appealProcessService.lastProcessTasksEntryStatusChangedAt = tasksData.entryStatusChangedAt;

                if (!this.appealProcessService.activeTasks.length) {
                    this.updateCamundaProcessTasks(tasksData);
                }
            });
    }

    public async updateCamundaProcessTasks(tasksData: any) {
        const users = await this.tasksService.getUsers();
        const resultTasks: any = this.processService.getTasksList(tasksData.userTasks, users);
        const serviceTasks = this.processService.getTasksList(tasksData.serviceTasks, users);

        this.appealProcessService.activeTasks = resultTasks.activeTasks;
        this.appealProcessService.completedTasks = resultTasks.completedTasks;
        this.appealProcessService.activeServiceTasks = serviceTasks.activeTasks;
        this.appealProcessService.completedServiceTasks = serviceTasks.completedTasks;
        this.appealProcessService.processTimers = tasksData.timers;
        this.appealProcessService.eventGateways = tasksData.eventGateways;
        this.appealProcessService.tasks = resultTasks.activeTasks.concat(resultTasks.completedTasks);
    }

    private async _updateTasksList(
        mainId: string,
        guid: string,
        camundaBusinessInfoId: string,
    ) {
        const result = await this.processService.updateTasksList(mainId, guid, camundaBusinessInfoId);

        this.appealProcessService.activeTasks = result.userTasks.activeTasks;
        this.appealProcessService.completedTasks = result.userTasks.completedTasks;
        this.appealProcessService.activeServiceTasks = result.serviceTasks.activeTasks;
        this.appealProcessService.completedServiceTasks = result.serviceTasks.completedTasks;
        this.appealProcessService.processTimers = result.timers;
        this.appealProcessService.eventGateways = result.eventGateways;
        this.appealProcessService.tasks = result.userTasks.activeTasks.concat(result.userTasks.completedTasks);

        // так как было произведено первоначальное считывание данных коллекции camundaBusinessInfo
        // запоминаем значение поля entryStatusChangedAt для его дальнейшего отслеживания
        this.appealProcessService.initProcessTasksEntryStatusChangeDetection(result.entryStatusChangedAt);

        // подписываемся на изменения поля entryStatusChangedAt
        this.processTasksEntryStatusChangedSubscription = this.appealProcessService.processTasksEntryStatusChanged
            .subscribe(async () => {
                await this.appealService.refreshAppealAfterChangeStatus();
                this.documentService.correctSubserviceDocGroups();
            });

        if (this.appealProcessService.activeTasks.length > 0 || result.eventGateways.some(item => item.status === 'ACTIVE')) {
            clearInterval(this.appealProcessService.updateTasksInterval);
        }

        await this._initTabAfterGetProcessInfo();

        return result;
    }

    /**
     * Активация вкладки связанной с задачами процесса
     */
    private async _initTabAfterGetProcessInfo() {
        const nextRoute = this.storage.getFromCache('nextRoute');
        this.storage.cacheItem('currentAppealId', this.appeal._id);
        const tab = this._findTabFromPath();
        if (nextRoute && !tab) {
            this.storage.removeFromCache('nextRoute');
            if (nextRoute === 'active-tasks' && !this.canShowActiveTasksTab()) {
                this.storage.cacheItem('nextRoute', 'subservices');
                await this.activateTab(this.TABS.SUBSERVICES);
                this.initialized = true;

                return;
            }

            if (nextRoute === 'completed-tasks' && !this.canShowCompletedTasksTab()) {
                this.storage.cacheItem('nextRoute', 'subservices');
                await this.activateTab(null);
                this.initialized = true;

                return;
            }

            if (nextRoute === 'history' && !this.canShowHistoryTab()) {
                this.storage.cacheItem('nextRoute', 'subservices');
                await this.activateTab(null);
                this.initialized = true;

                return;
            }

            await this.activateTab(nextRoute);
            this.initialized = true;

            return;
        }

        if (tab) {
            await this.activateTab(tab);
            this.initialized = true;

            return;
        }

        if (this.canShowActiveTasksTab()) {
            await this.activateTab(this.TABS.ACTIVE_TASKS);
            this.initialized = true;

            return;
        }

        if (this.canShowCompletedTasksTab()) {
            await this.activateTab(this.TABS.COMPLETED_TASKS);
            this.initialized = true;

            return;
        }

        await this.activateTab(this.TABS.SUBSERVICES);
        this.initialized = true;

        /*if (!this.appealProcessService.isProcessActive && Object.keys(this.subservice).length > 0) {
            this.appealService.isActiveProcessInAppeal = false;
            this._getFirstAppealSubserviceActiveActions();
        }*/
    }

    private _findTabFromPath() {
        return Object.values(this.TABS).find(tab => this.router.url.includes(tab));
    }

    private _getFirstAppealSubserviceActiveActions() {
        this.appealSubserviceActions = [];

        // Если не активны процессы камунды, отображаем действия услуги
        if (!this.appealService.isActiveProcessInAppeal && this.appeal.status.code !== 'complete') {
            const appealSubserviceData = this.appealSubservicesService.data[this.appeal.subservice.guid];
            this.appealSubserviceActions = appealSubserviceData.actions;
        }
    }

    /**
     * Активность вкладки Объекты
     */
    public get disabledObjectsTab() {
        return this.appeal.status.code === this.STATUSES.ARCHIVE && !this.appeal.inPreArchive;
    }

    /**
     * Активность вкладки Субъекты
     */
    public get disabledSubjectsTab() {
        return this.appeal.status.code === this.STATUSES.ARCHIVE && !this.appeal.inPreArchive;
    }

    /**
     * Активность вкладки Документы
     */
    public get disabledDocumentTab() {
        return this.appeal.status.code === this.STATUSES.ARCHIVE && !this.appeal.inPreArchive;
    }

    /**
     * Активность вкладки Конверты
     */
    public get disabledEnvelopesTab() {
        return this.appeal.status.code === this.STATUSES.DRAFT || this.appeal.documents.length === 0;
    }

    /**
     * Активность вкладки Сообщения
     * @returns {boolean}
     */
    public get disabledMessagesTab() {
        const objectsWithMessaging = this.appeal.objects.filter(item => item.messaging && item.messaging.length > 0);

        return !(objectsWithMessaging.length > 0 && this.appeal._id);
    }

    /**
     * Наличие файлов в документах дела
     */
    public get filesExist() {
        const documentsWithFiles = this.appeal.documents.filter(item => item.files && item.files.length > 0);

        return documentsWithFiles.length > 0;
    }

    public get existCamundaProcess() {
        return this.subservice.camundaProcess && Object.keys(this.subservice.camundaProcess).length > 0;
    }

    /**
     * КНМ сохранения данных при попытке активации другой вкладки при клике на нее
     * @param tab - активируемая вкладка
     * @param replaceUrl - необходимость замены URL (используется для возврата к списку)
     */
    public async activateTab(tab: string | null, replaceUrl: boolean = true) {
        if (this.router.url.includes('appeals/create')) {
            await this.router.navigate(['supervisor', 'appeals', 'create', this.params.subserviceId]);
        } else if (this.appeal._id) {
            await this.router.navigate(['supervisor', 'appeals', 'edit', this.appeal._id, tab ? tab : ''], { replaceUrl });
        }
    }

    public checkIsActive(tab) {
        return this.router.url.includes(tab);
    }

    /**
     * Изменение режима отображения / скрытия печатных форм в зависимости о того какая вкладка активируется
     * @param tab - активируемая вкладка
     */
    public changeHiddenPrintForms(tab) {
        if (this.printFormsWithoutDocs) {
            this.printFormsWithoutDocs.forEach(item => {
                if (tab === this.TABS.HISTORY) {
                    item.hide = item.formType.indexOf('dealHistory') === -1;
                } else if (tab === this.TABS.DOCUMENTS) {
                    item.hide = item.formType.indexOf('dealHistory') !== -1;
                }
            });
        }
    }

    /**
     * Определение наличия видимых ПФ в выпдающем списке
     * @returns {boolean}
     */
    public existsVisiblePrintFormsWithoutDocs() {
        return this.printFormsWithoutDocs && this.printFormsWithoutDocs.filter(item => !item.hide).length > 0;
    }

    /**
     * Активация вкладки после КНМ необходимости сохранения данных в отдельных компонентах дела (случай открытия отдельного элемента на редактирование)
     * @param tab - активируемая вкладка
     */
    public activateTabAfterCheckSave(tab) {
        const disabled = tab === this.TABS.OBJECTS && this.disabledObjectsTab ||
            tab === this.TABS.DOCUMENTS && this.disabledDocumentTab || tab === this.TABS.ENVELOPES && this.disabledEnvelopesTab;
        if (!disabled) {
            this.changeHiddenPrintForms(tab);
            this._correctTabIfRequired(tab);
            this.router.navigate([tab]);
        }
    }

    public _correctTabIfRequired(tab) {
        if (tab === '') {
        return null;
        }
    }

    /**
     * Сохранение дела (без валидации)
     */
    public async saveAppeal() {
        this.appealProcessingType = 'save';
        if (!this.appeal._id) {
            this.appealService.activeTabInAppealBeforeCreate = this.activeTab; // сохранение активной вкладки
        }
        await this._applyEditedElements();
    }

    /**
     * Активация определенного действия
     * @param action - активируемое действие
     * @param appealSubservice - услуга дела, в которой инициализируется действие (null если действие из дела, а не услуги)
     */
    public activateAction(action, appealSubservice = null, withoutAppealUpdate = false) {
        // Если осуществляется переход на новый статус, а не откат статуса
        if (!action) {
            return;
        }

        if (!appealSubservice) {
            this.activeAction = action;
            this.appealStatusService.activeAction = action;
        }
        this.documentService.correctSubserviceDocGroups(!appealSubservice);

        if (this.appealDocumentsComponent) {
            this.appealDocumentsComponent.documentGroupsComponents
                .forEach(item => {
                    item.initGroups();
                });
        }
        this.validateService.validateAppeal(true);
        if (!withoutAppealUpdate) {
            this.timeoutIds.push(setTimeout(() => this.appealService.fetchCurrentAppeal(), 3000));
        }
    }

    /**
     * Переход дела на новый статус
     * @param actionParams - объект вида {action: активируемое действие,
     *                            appealSubservice: услуга в деле, для которой выполняется переход на новый статус (необязательный параметр при глобальном действии)
     *                            isRollBack: операция по откату предыдущего статуса}
     */
    public async executeAction(actionParams) {
        // Обработка для конвертированных дел
        if (this.appeal.converted && !this.appeal.correctUnit) {
            this.currentOrganization = this.storage.getItem('currentOrganization');
            // Присваиваем значение unit
            this.appeal.correctUnit = true;
            this.appeal.unitId = this.currentOrganization._id;
            this.appeal.unit = {
                id: this.currentOrganization._id,
                name: this.currentOrganization.name,
                shortName: this.currentOrganization.shortName,
            };
        }
        this.processingAction = actionParams.action;
        this.appealStatusService.currentExecuteAction = actionParams.action;
        this.appealProcessingType = 'changeStatus';

        this.isRollBack = actionParams.isRollBack;
        this.appealProcessingSubservice = actionParams.appealSubservice ? actionParams.appealSubservice : null;
        this.activateAction(this.processingAction, this.appealProcessingSubservice, true);

        const oldStatusCode = this.appealService.appeal.status.code;
        await this._applyEditedElements(); // применение данных редактируемых в данный момент сущностей

        if (actionParams.action.code === 'process' && oldStatusCode === 'draft' && this.appealService.appeal.status.code !== 'draft') {
            // после успешной регистрации дела перезагрузка дела целиком
            await this.router.navigate([this.moduleBaseUrl, 'appeals', 'edit-reload', this.appealService.appeal._id], {replaceUrl: true});

            return;
        }

        this.refetchInterval = setInterval(() => {
            if (this.appealService.appeal.status.code !== 'process' && !this.appealService.appeal.datePlaneFinish) {
                this.appealService.fetchCurrentAppeal();
            } else {
                clearInterval(this.refetchInterval);
            }
        }, 3000);
        this.intervalIds.push(this.refetchInterval);

        await this._getProcessInfo();
    }

    private async _getProcessInfo() {
        let retries = 10;
        let subservicesCollection = SUBSERVICES_COLLECTION;
        while (!this.appealProcessService.isProcessActive && retries > 0) {
            retries--;
            this.appeal = await this.rest.find('appeals', this.appeal._id);
            this.appealService.appeal = this.appeal;
            this.subservice = await this.rest.find(subservicesCollection, this.appeal.subservice.id);

            if (!this.subservice && subservicesCollection === SUBSERVICES_COLLECTION) {
                this.subservice = await this.rest.find(SUBSERVICES_ARCHIVE_COLLECTION, this.appeal.subservice.id);
                if (!this.subservice) {
                    const errorMessage = 'Услуга не найдена, id = ' + this.appeal.subservice.id;

                    this.toaster.error(errorMessage);
                    await this.errorLoggingService.log(this.errorLoggingService.APPEAL, new Error(errorMessage));

                    return;
                } else {
                    subservicesCollection = SUBSERVICES_ARCHIVE_COLLECTION;
                }
            }
            await this._initProcessInfo();
            await new Promise(resolve => setTimeout(() => resolve(), 500));
        }
    }

    /**
     * Проверяет, есть ли в деле непрочитанный ответ от заявителя
     */
    public checkHasUnreadUserAnswer() {
        return this.appeal && this.appeal.subservice.feedbackMessageUnchecked;
    }

    /**
     * Применение изменений в редактируемых в текущий момент времени элементах дела
     * (в любой момент времени таким может быть только один элемент: услуга, объект или документ дела)
     */
    private async _applyEditedElements() {
        if (this.appealSubservicesComponent && this.appealSubservicesComponent.editAppealSubserviceCardComponent && this.appealSubservicesComponent.editAppealSubserviceCardComponent.mode === this.MODE.EDIT) {
            this.appealSubservicesComponent.editAppealSubserviceCardComponent.apply(true);
        } else if (this.appealObjectsComponent && this.appealObjectsComponent.editObjectCardComponent && this.appealObjectsComponent.editObjectCardComponent.mode === this.MODE.EDIT) {
            this.appealObjectsComponent.editObjectCardComponent.apply(true);
        } else if (this.appealSubjectsComponent && this.appealSubjectsComponent.editSubjectCardComponent && this.appealSubjectsComponent.editSubjectCardComponent.mode === this.MODE.EDIT) {
            this.appealSubjectsComponent.editSubjectCardComponent.apply(true);
        } else if (this.appealDocumentsComponent && this.appealDocumentsComponent.editDocumentCardComponent && this.appealDocumentsComponent.editDocumentCardComponent.mode === this.MODE.EDIT) {
            await this.appealDocumentsComponent.editDocumentCardComponent.apply(true);
        } else {
            await this._continueProcessingAppealAfterApplyEditedElement();
        }
    }

    /**
     * Продолжение процесса сохранения дела после применения редактируемых элементов дела
     */
    private async _continueProcessingAppealAfterApplyEditedElement() {
        if (this.appealSubservicesService.isProcessingAddSubjectToAppealSubserviceGroup) {
            // Если инициализирован режим добавления участника из карточки услуги
            if (this.appealSubservicesService.processingAddSubjectToAppealSubserviceGroupData.isComplete) {
                // возврат после добавления / отмены добавления объекта
                this._afterAddNewSubjectToAppealSubserviceGroup();
            } else {
                this._processingAddNewSubjectToAppealSubserviceGroup();
            }
        } else {
            const validateAppeal = this.appealProcessingType !== 'save' && this.processingAction.additionActions.indexOf('notValidate') === -1;
            const appealValid = this.validateService.validateAppeal(validateAppeal);
            if (appealValid) {
                // Сбрасываем флаг валидации в деле, чтобы он не переходил на последующие после сохранения / перехода на новый статус действия с делом
                this.validateService.processValidate = this.validateService.processValidate = false;
                // Если дело еще не было сохранено на сервере
                if (!this.appeal._id) {
                    await this.appealSaveService.saveAppeal();
                } else {
                    try {
                        this.appealSaveService.appeal.documents = _.cloneDeep(this.appeal.documents);
                        await this.appealSaveService.saveAppeal();

                        if (this.appealProcessingType === 'changeStatus') {
                            await this._prepareExecuteAction();
                        } else {
                            this.toaster.success(this.localizations.appeals.notifications.successfully_saved);
                        }
                    } catch (error) {
                        await this.errorLoggingService.log(this.errorLoggingService.APPEAL, error);
                        this.toaster.error(error);
                    }
                }
            } else {
                setTimeout(() => {
                    if (this.refetchInterval) {
                        clearInterval(this.refetchInterval);
                    }
                }, 4000);
                this.clearProcessExecuteActionData();
                await this.errorLoggingService.log(this.errorLoggingService.APPEAL, new Error(this.localizations.appeals.notifications.error_while_saving));
                this.toaster.error(this.localizations.appeals.notifications.error_while_saving);
            }
        }
    }

    private _afterAddNewSubjectToAppealSubserviceGroup() {
        this.activeTab = this.TABS.SUBSERVICES;
    }

    private _processingAddNewSubjectToAppealSubserviceGroup() {
        // @ts-ignore
        this.activeTab = this.TABS.SUBJECTS;
        setTimeout(() => {
            this.appealSubjectsComponent.addSubject();
        }, 100);
    }

    /**
     * Очистка элементов, отвечающих за визуальное отображение процесса выполнения действия
     */
    public clearProcessExecuteActionData() {
        this.processingAction = null;
        this.parentReason = null;
        this.reasonItems = null;
        this.reasonFields = null;
        this.appealStatusService.currentExecuteAction = null;
        if (this.appealProcessingSubservice) {
            this.appealSubservicesService.data[this.appealProcessingSubservice.guid].isProcessExecuteAction = false;
        }
    }

    /**
     * Получение печатных форм с сервера
     */
    private async _getPrintForms() {
        const subservicesIds = [];
        subservicesIds.push(this.subservice.serviceId);
        const search = [];
        // Получение только не удаленных записей
        search.push({
            field: 'isDeleted',
            operator: 'neq',
            value: true,
        });
        // Определение организация МФЦ + ОГВ
        const orgs = [this.appeal.unit.id];
        if (this.appeal.subservice.responsibleOrganization) {
            orgs.push(this.appeal.subservice.responsibleOrganization.id);
        }
        // Настройка других параметров поиска
        search.push({
            orSubConditions: [
                {
                    field: 'serviceIds',
                    operator: 'in',
                    value: subservicesIds,
                },
                {
                    field: 'orgIds',
                    operator: 'in',
                    value: orgs,
                },
            ],
        });
        search.push({
            andSubConditions: [
                {
                    field: 'notUseServiceIds',
                    operator: 'nin',
                    value: subservicesIds,
                },
                {
                    field: 'notUseOrgIds',
                    operator: 'neq',
                    value: this.appeal.unit.id,
                },
            ],
        });

        try {
            const response = await this.rest.search('printForms', {search: {search}, size: 100});
            // Корректировка печатных форм в соответствии с типом дела (комплексное или нет) и формирование массива ПФ не привязанных к документам
            const filteredPrintForms = this.documentService.filterPrintForms(response);
            this.printForms = filteredPrintForms.printForms; // все используемые печатные формы
            this.appealService.printForms = this.printForms;
            this.printFormsWithoutDocs = this.filterPrintFormsByAppealStatus(filteredPrintForms.printFormsWithoutDocuments); // печатные формы без документов
        } catch (error) {
            this.toaster.error(this.localizations.events.notifications.print_form_errors_occurred + error);
        }
    }

    /**
     * Фильтрация списка печатных форм формируемых по кнопке "Печать" по статусу дела
     * @param printForms - начальный массив печатных форм
     * @returns {any}
     */
    public filterPrintFormsByAppealStatus(printForms) {
        const result = [];
        let formTypes = ['dealHistory'];
        if (this.appeal.status && this.appeal.status.code === 'consultation') {
            formTypes.push('consultation');
        } else {
            if (this.appeal.status.code === this.STATUSES.DRAFT) {
                formTypes.push('appeal');
            }
            if (this.appeal.status.code === 'process' ||
                (this.appeal.status.mainCode && this.appeal.status.mainCode === 'process')) {
                formTypes.push('appeal');
                formTypes.push('bringRegister');
            }
            if (['beforeIssued', 'issued', 'issuedReturnToOGV', 'issuedOther', 'rejected'].includes(this.appeal.status.code)) {
                formTypes.push('issueRegister');
            }
        }

        if (this.appealService.appealPayments && this.appealService.appealPayments.length) {
            formTypes.push('payment');
        }

        if (formTypes.length > 0) {
            formTypes = _.uniq(formTypes);

            printForms.forEach(form => {
                formTypes.forEach(type => {
                    if (form.formTypeCode === type) {
                        result.push(form);
                    }
                });
            });
        }

        return result;
    }

    /**
     * Обновление списка печатных форм
     * @param whateverUpdate - обновлять список в любом случае, а не только при их отсутствии
     */
    private async _updateListPrintForms(whateverUpdate = false) {
        if (!this.printForms || whateverUpdate) {
            await this._getPrintForms();
        }
    }

    /**
     * Создание документа на основе печатной формы
     * @param printForm - печатная форма
     */
    public createDocumentFromPrintForm(printForm) {
        if (printForm.formType.indexOf('dealHistory') !== -1) {
            this.sendHistoryPrintForm(printForm); // Формирование печатной формы по истории дела
        }
    }

    /**
     * Формирование печатной формы по истории дела
     * @param printForm
     */
    public async sendHistoryPrintForm(printForm: any): Promise<void> {
        const data = this.printFormService.prepareAppealHistoryData(this.appeal, this.historyItems);
        this.progressService.start();
        const appealData = this.printFormService.prepareAppealData(this.appeal, this.subservice, null, printForm);
        try {
            const response: any = await this.printingService
                .renderPrintForm(printForm._id, this.appeal._id, 'appeals', appealData, data, null, this.appeal.unit.id, this.appeal.subservice.responsibleOrganization.id);
            await this.filesService.downloadAndSaveFile(response.file._id, response.file.name);
        } catch (error) {
            this.toaster.html(error.errorMessage || error);
        } finally {
            this.progressService.done();
        }
    }

    /**
     * Возвращает класс для отображения иконки для типа печатной формы
     * @param printForm - обрабатываемая печатная форма
     * @returns {any}
     */
    public getPrintFormIcon(printForm) {
        const icons = {
            docx: 'fa-file-word-o',
            doc: 'fa-file-word-o',
            xlsx: 'fa-file-excel-o',
            pdf: 'fa-file-pdf-o',
            other: 'fa-file-text-o',
        };

        const arr = printForm.formType.split('.');
        const extension = arr[1].toLowerCase();

        return icons[extension] ? icons[extension] : icons['other'];
    }

    /**
     * Сохранение истории по делу после инициализации компонента
     * @param historyItems - массив элементов истории по делу
     */
    public afterGetHistory(historyItems) {
        this.historyItems = historyItems;
    }

    /**
     * Сохранение измененного состава конвертов дела в переменную
     * (для того, чтобы не запрашивать повторно данные с сервера при повторной инициализации компонента работы с конвертами)
     * @param envelopes
     */
    public changeAppealEnvelopes(envelopes) {
        this.envelopes = envelopes;
    }

    /**
     * Сохраненнного измененного состава сообщений дела в переменную
     * @param messages
     */
    public changeAppealMessages(messages) {
        this.messages = messages;
    }

    /**
     * Подготовка к переходу на новый статус, заполнение дополнительных параметров для некоторых видов действий
     */
    private async _prepareExecuteAction() {
        // Если действие требует дополнительной обработки
        if (this.processingAction && this.appealStatusService.actionsWithAdditionalOperations[this.processingAction.code]) {
            this._prepareReasonsData(this.processingAction.code);

            return;
        }

        // Запуск процедуры обработки действия
        await this._processExecuteAction();
    }

    private _prepareReasonsData(code) {
        this.reasonItem = this.appealStatusService.actionsWithAdditionalOperations[code]; // текущее действие
        if (this.reasonItem.reference) {
            this.reasonItems = this.appealStatusService.prepareReasonsForAction(this.reasonItem.reference, this.reasonItem.type, this.appealProcessingSubservice); // набор уникальных элементов из всех услуг дела (либо одной услуги)
        }

        if (this.reasonItem.fields) {
            this.reasonFields = this.reasonItem.fields;
        }
        if (this.reasonItem.items) {
            this.reasonItems = this.reasonItem.items;
        }
        if (this.reasonItem.reference &&  this.reasonItems.length === 0) {
            // Если в услугах нет элементов для выбора из списка, обрабатываем пустым объектом
            this.completeReasons({});
        } else {
            this.reasonTitle = this.reasonItem.title; // заголовок для модального окна
            this.reasonModalComponent.showModal(); // отображение модального окна
        }
    }

    /**
     * Обработка выбранных (заполненных) данных в модальном окне для доп.обработки выполняемого действия
     * @param data - данные
     */
    public async completeReasons(data) {
        let params = {};
        if (!this.parentReason) {
            if (this.reasonItem.code) {
                params[this.reasonItem.field && this.parentReason ? this.reasonItem.field : this.reasonItem.code] = data;
            }

            if (this.reasonItem.fields) {
                params = data;
            }
        }
        // Если есть дочерние действия для выбранного элемента, которые обрабатываются в модальном окне
        if (data.additionalActions && data.additionalActions.length > 0) {
            this.parentReason = {additionalActionCode: data.additionalActions[0], parentCode: this.reasonItem.code, params: data};
            this.reasonItem = null;
            this.reasonItems = null;
            this.reasonFields = null;
            this._prepareReasonsData(data.additionalActions[0]);
        } else {
            if (this.parentReason) {
                // Если обрабатываются вложенные действия, то значения объединяются
                this.parentReason.params[this.reasonItem.field] = data;
                params = {};
                params[this.parentReason.parentCode] = this.parentReason.params;
            }
            await this._processExecuteAction(params); // запуск процедуры перехода на новый статус с учетом выбранных (заполненных) параметров
        }
    }

    /**
     * Переход дела на новый статус
     * @param properties - объект с настройками доп.обработки действия
     */
    private async _processExecuteAction(properties: any = {}) {
        if (this.isRollBack) {
            properties = {rollback: null};
        }

        try {
            const changedAppeal: any = await this.appealStatusService.executeAction(this.processingAction, properties, this.appealProcessingSubservice, this.currentTicket);
            this.clearProcessExecuteActionData(); // Очистка визуальных компонентов процесса выполнения действия
            // Если было осуществлено архивирование дела
            if (changedAppeal.status && changedAppeal.status.code === this.STATUSES.ARCHIVE) {
                // Запрашиваем дело с сервера
                await this.rest.find('appealsArchive', changedAppeal._id);

                // Запрашиваем дело с сервера
                const notCleanupArchiveAppeals = this.storage.getItem('notCleanupArchiveAppeals');
                let archiveCollection = 'appealsArchive';
                if (notCleanupArchiveAppeals) {
                    archiveCollection = 'appealsPreArchive';
                }
                // tslint:disable-next-line:no-shadowed-variable
                const responseAppeal: any = await this.rest.find(archiveCollection, changedAppeal._id);

                if (archiveCollection === 'appealsArchive' && responseAppeal.subjects.length > 0) {
                    responseAppeal.subjects = [];
                    responseAppeal.subservice.subjects = [];
                }
                await this._processAfterArchiveAppeal(responseAppeal);
            } else {
                // TODO убрать после правок на беке
                const changedAppealSubservice = changedAppeal.subservice;
                delete changedAppeal.subservice;

                // TODO убрать после правок на беке
                delete changedAppeal.subservices;
                this.appeal = Object.assign(this.appeal, changedAppeal);
                this.appealService.appeal = this.appeal;
                this.actions = this.appealStatusService.initAppealActions();
                this.activeAction = null;
                if (this.actions.length) {
                    this.activeAction = this.actions[0];
                }

                this.appeal.subservice = Object.assign(this.appeal.subservice, changedAppealSubservice);
                this.appealSubservicesService.data[this.appeal.subservice.guid].isProcessExecuteAction = false;
                // Корректировка доступных действий
                this.appealSubservicesService.correctActions(this.appeal.subservice);
                // Если в деле есть процессы камунды
                if (this.appeal.subservice.camundaProcessInfo) {
                    setTimeout(async () => {
                        const isActive = await this.appealService.checkCamundaProcessActivity(this.appeal.subservice.camundaProcessInfo.id);
                        if (!isActive) {
                            this._getFirstAppealSubserviceActiveActions();
                        }
                    }, 3000);
                }

                // Перенастройка параметров документов дела
                this.documentService.calculateDocumentsPermissions();

                // Обновляем список печатных форм
                await this._updateListPrintForms(true);

                // Если дело переведено в статус "В обработке" или "Консультация" запускаем сохранение объектов в реестре клиентов
                if ((this.appeal.status.code === 'process' || this.appeal.status.code === 'consultation') && !this.appealProcessingSubservice) {
                    // this.appealSaveService.saveObjectsInReestr();
                    await this._initProcessInfo();
                    await this.createFakeHistoryItem();
                }

                if (this.appeal.status.code === 'complete' && this.appeal.eventId) {
                    await this.eventsService.checkCompleteAllProcessInEvent(this.appeal.eventId);
                }

                // Обновление данных базового дела
                this.storage.setItem('baseAppeal', this.appeal);

                this.toaster.success('Статус успешно изменен');
            }
        } catch (error) {
            this.clearProcessExecuteActionData(); // Очистка визуальных компонентов процесса выполнения действия
            await this.errorLoggingService.log(this.errorLoggingService.APPEAL, error);
            this.toaster.error('При попытки смены статуса произошла ошибка. Статус не был изменен. Попробуйте совершить операцию еще раз!');
        }
    }

    private async _processAfterArchiveAppeal(appeal) {
        this.appeal = appeal;
        this.appealService.appeal = this.appeal;
        this._baseInitServiceData();
        await this._initServicesData();

        this.storage.setItem('baseAppeal', this.appeal);
        this.appealService.baseAppeal = _.cloneDeep(this.appeal);
        if (!this.appeal.inPreArchive && this.appeal.subjects.length) {
            this.appeal.subjects = [];
            this.appeal.subservice.subjects = [];

            await this.appealSaveService.saveAppeal();
        }

        this.toaster.success('Статус успешно изменен');
    }

    public async createFakeHistoryItem() {
        const historyItem = {
            collectionName: 'appeals',
            mainId: this.appeal._id,
            subEntryName: null,
            subEntryGuid: null,
            parentEntries: 'history',
            code: 'sendRequest',
            subCode: null,
            type: 'entry',
            description: 'Отправлен запрос "Размещение сведений в реестре электронных паспортов контрольно-надзорных мероприятий"',
            entityType: null,
            data: null,
        };

        await this.rest.create('history', historyItem);
    }

    /**
     * Получение параметров для статуса дела
     * @param property
     */
    public getStatusProperty(property) {
        const appeal = this.appealService.appeal;
        if (!appeal || !appeal.subservice) {
            return;
        }

        const statuses = this.subservice.statusModel.status;
        const status = statuses.find(item => item.code === appeal.subservice.status.code);
        if (status) {
            return property === 'background' ? 'bg-' + status.theme + '-300' : status[property];
        }

        return this.appealStatusService.getStatusProperty(appeal.status, property);
    }

    /**
     * Изменение режима регистрации дела с неполным составом документов
     */
    public changeAppealIncompleteSetOfDocuments() {
        if (this._checkExistSubservicesInDraftStatus()) {
            this.appeal.incompleteSetOfDocuments = !this.appeal.incompleteSetOfDocuments;
        }
    }

    /**
     * Обновление информации по делу (после выполнения запроса с переводом статуса)
     */
    public async updateAppeal() {
        this.appeal = await this.rest.find('appeals', this.appeal._id);
        this.appealService.appeal = this.appeal;
        await this._initServicesData();
    }

    // Смена роутинга
    public ngOnDestroy() {
        if (this.processTasksEntryStatusChangedSubscription) {
            this.processTasksEntryStatusChangedSubscription.unsubscribe();
        }

        if (this.processTasksPollTriggeredSubscription) {
            this.processTasksPollTriggeredSubscription.unsubscribe();
        }

        this.quickRequestsService.clearData();
        this.appealProcessService.stopProcessTasksPolling();

        this.filesQueueService.close();

        this.timeoutIds.forEach(clearTimeout);
        this.intervalIds.forEach(clearInterval);
    }

    /**
     * КНМ наличия услуг в деле в статусе "Черновик"
     * @returns {boolean}
     */
    private _checkExistSubservicesInDraftStatus() {
        return this.appeal.subservice.status.code === this.STATUSES.DRAFT;
    }

    public onSelectSubservice() {
        this.actions = this.appealStatusService.initAppealActions();
        this.activeAction = this.appealStatusService.activeAction;

        this._updateListPrintForms(true);
    }

    public afterCompleteCamundaTasks() {

    }

    /**
     * Переход в режим добавления нового участника для определенной группы услуги дела
     * @param data
     */
    public addNewSubjectToAppealSubserviceGroup(data) {
        this.appealSubservicesService.isProcessingAddSubjectToAppealSubserviceGroup = true;
        this.appealSubservicesService.processingAddSubjectToAppealSubserviceGroupData = data;
        this.appealSubservicesComponent.editAppealSubserviceCardComponent.apply(true);
    }

    public checkAllowAction(action) {
        switch (action.code) {
            case 'annulled':
                return this.appeal.status.code === this.STATUSES.DRAFT;
            case this.STATUSES.ARCHIVE:
                const user = this.storage.getItem('user');

                return !user.externalPermissions || user.isSuperAdmin;
            default:
                return true;
        }
    }

    public get isAllowAppealActions() {
        const allowStatuses = [this.STATUSES.DRAFT, 'complete', 'receiveDocsReject', 'serviceReject'];

        return !!(this.appeal._id && allowStatuses.indexOf(this.appeal.status.code) !== -1);
    }

    /**
     * Перевод дела из преархива в архив - обезличивание
     */
    public async sendToArchive() {
        this.isProcessingCleanupAndMoveToArchive = true;
        try {
            await this.httpService.post(Config.server + Config.api + 'mfc/cleanupAndMoveToArchive', {appealId: this.appeal._id});
            const archivedAppeal = await this.rest.find('appealsArchive', this.appeal._id);
            this._processAfterArchiveAppeal(archivedAppeal);
        } catch (error) {
            this.toaster.error(error);
        }
        this.isProcessingCleanupAndMoveToArchive = false;
    }

    /**
     * Возврат дела из преархива
     */
    public async returnFromArchive() {
        this.isProcessingRollbackFromPreArchive = true;
        try {
            await this.httpService.post(Config.server + Config.api + 'mfc/rollbackFromPreArchive', {appealId: this.appeal._id});
            const correctAppeal = await this.rest.find('appeals', this.appeal._id);
            this.appeal = correctAppeal;
            this.appealService.appeal = this.appeal;
            this.appealSaveService.appeal = correctAppeal;
            this.appealService.baseAppeal = this.appeal;
            this.storage.setItem('baseAppeal', this.appeal);

            this.appealStatusService.appeal = this.appeal;
            this.actions = this.appealStatusService.initAppealActions();
            this.activeAction = this.appealStatusService.activeAction;
            this.appealSubservicesService.correctActions(this.appeal.subservice);
        } catch (error) {
            error = (typeof error === 'object' && error.error ? error.error.description : error);
            this.toaster.error(error);
        } finally {
            this.isProcessingRollbackFromPreArchive = false;
        }
    }
}

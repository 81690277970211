import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
    AccessService,
    ModalDialogComponent,
    RestService,
    StorageService,
    ToasterService,
    TranslateService,
} from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import { AppealService } from '../../appeal.service';
import { AppealValidateService } from '../../appeal-validate.service';
import { AppealSubserviceCardComponent } from './components/subservice-card/appeal-subservice-card.component';
import { DocumentService } from '../documents/document.service';
import { AppealSaveService } from '../../appeal-save.service';
import { AppealSubservicesService } from '../../appeal-subservices.service';
import { Permission } from '../../../../../common/services/permission';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import pick from 'lodash-es/pick';
import { map, take } from 'rxjs/operators';
import { RsoService } from '../../../../../common/services/rso.service' ;
import { SUBSERVICES_ARCHIVE_COLLECTION, SUBSERVICES_COLLECTION } from '../../../../../common/constants';

@Component({
    selector: 'appeal-subservices',
    templateUrl: './appeal-subservices.component.html',
    styles: [
        '.drop-zone { border: 2px dashed transparent; padding: 10px; }',
        '.drop-zone.over { border-color: #bbd2dd; }',
    ],
})
export class AppealSubservicesComponent implements OnInit {

    @ViewChild('modalDialog', { static: false }) public modalDialogComponent: ModalDialogComponent; // модальное окно для подтверждения / отмены действий внутри компонента
    @ViewChild('editCard', { static: false }) public editAppealSubserviceCardComponent: AppealSubserviceCardComponent; // Компонент - карточка редактирования услуги дела

    @Output() public onAddNewSubjectToAppealSubserviceGroup = new EventEmitter<any>();

    public appeal;
    public subservice;
    public editAppealSubservice = null; // Редактируемая услуга дела
    public isEditAppealSettings = false; // Флаг того, что компонент находится в режиме редактирования настроек дела
    public isProcessSetupSubservicesInAppeal = false; // Режим настройки услуг в деле
    public currentAppealSubservicesGuids = []; // Текущие услуги дела (перед процессом настройки порядка и последовательности)
    public activeTab; // Активная вкладка
    public permissions = Permission; // описание всех доступных прав доступа
    public subservicesStructure; // древовидная структура услуг дела (с последовательно-параллельным режимом выполнения)
    public localizations;
    public isDisabledByRso = false;

    public constructor(
        public accessService: AccessService,
        private appealService: AppealService,
        private appealSaveService: AppealSaveService,
        private restService: RestService,
        private router: Router,
        private appealSubservicesService: AppealSubservicesService,
        private validateService: AppealValidateService,
        private documentService: DocumentService,
        private saveService: AppealSaveService,
        private toaster: ToasterService,
        private translate: TranslateService,
        private storage: StorageService,
        private rsoService: RsoService,
    ) {
    }

    public async ngOnInit() {
        this.appealService.editing = false;
        this.storage.removeFromCache('nextRoute');
        this._loadTranslations();
        this.appealSaveService.nextSection = 'subservices';
        this.appeal = this.appealService.appeal;
        if (!this.appeal) {
            this.storage.cacheItem('nextRoute', 'subservices');
            const route = this.router.url.split('/subservices')[0];
            console.log('base route', route);
            await this.router.navigate([route]);
        } else {
            this.subservice = this.appealService.subservice;
            if (this.appealService.isProcessSetupAppealSubservices || !this.appeal.subservice && this.appeal.complexSubservice) {
                this.isProcessSetupSubservicesInAppeal = true;
                this._generateAppealSubservicesStructure();
            }

            if (this.appealSubservicesService.isProcessingAddSubjectToAppealSubserviceGroup && this.appealSubservicesService.processingAddSubjectToAppealSubserviceGroupData.isComplete) {
                this.activeTab = 'subjects';
                if (!this.appeal.subservice.guid === this.appealSubservicesService.processingAddSubjectToAppealSubserviceGroupData.appealSubservice.guid) {
                    throw new Error('Сабсервис не найден');
                }

                this.onEditAppealSubservice(this.appeal.subservice);
            }
        }
        this.isDisabledByRso = this.rsoService.canNotEditAppeal(this.appealSubservicesService.appeal);
    }

    public _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals.subservices',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    /**
     * Инициализация режима редактирования услуги
     * @param appealSubservice - услуга дела
     */
    public onEditAppealSubservice(appealSubservice) {
        this.appealSubservicesService.tempData = cloneDeep(this.appealSubservicesService.data);
        this.editAppealSubservice = cloneDeep(appealSubservice);
        this.appealService.editing = true;
    }

    /**
     * Применение (отмена) изменений в деле, переход к режиму списка услуг дела
     * @param data - объект формата {appealSubservice: измененная услуга, continueProcessingAppeal: флаг продолжения операции с делом}
     *             - либо false при отмене изменений
     */
    public async onApplyAppealSubservice(data) {
        if (data) {
            if (this.appeal.subservice.guid !== data.appealSubservice.guid) {
                throw new Error('Сабсервис не найден');
            }

            this.appeal.subservice = cloneDeep(data.appealSubservice);
            // Обработка данных объектов в услуге
            this.appeal.objects.forEach(() => {
                // Применение изменений привязки объекта к услугам дела
                this.appealSubservicesService.correctEntitiesDataInSubserviceOnApply();
            });
            // Переинициализация состава документов в соответствии с произведенными настройками (возможно в процессе настройки были изменения варианта услуги)
            this.documentService.reInitSubserviceData(); // обновление настроек услуг в сервисе документов
            this.documentService.correctSubserviceDocGroups(); // корректировка состава групп документов

            // Если применение данных инициализировано при нажатии кнопкок "Сохранить дело" или "Действие (переход на новый статус)
            if (data.continueProcessingAppeal) {
                // this.onApply.emit(true);
            } else if (this.appeal._id) {
                try {

                    await this.saveService.saveAppeal();
                    this.toaster.success('Данные услуги успешно сохранены');
                } catch (error) {
                    this.toaster.error(error);
                }
            }
        } else {
            // Обновляем служебный объект данными после изменения
            this.appealSubservicesService.data = cloneDeep(this.appealSubservicesService.tempData);
        }
        // Запускаем перевалидацию услуги
        // this.validateService.appeal = this.appeal;
        this.validateService.validateAppeal(this.validateService.processValidate);

        this.editAppealSubservice = null;
    }

    /**
     * Инициализация режима редактирования услуги дела
     * @param data - данные для инициализации {tab: активная вкладка, appealSubserviceId: ID услуги дела для редактирования}
     */
    public initEditAppealSubservice(data) {
        this.activeTab = data.tab;
        if (this.appeal.subservice.id !== data.appealSubserviceId) {
            throw new Error('Сабсервис не найден');
        }

        this.onEditAppealSubservice(this.appeal.subservice);
    }

    /**
     * Определение возможности деактивации маршрута компонента {@link CanDeactivateGuard}
     */
    public canDeactivate(): Promise<boolean> {
        // Проверка на необходимость сохранения изменений в редактируемую в данный момент времени услугу дела
        if (!this.isDisabledByRso && this.editAppealSubservice && this.checkAppealSubserviceChange()) {
            this.modalDialogComponent.title = this.localizations.common.confirm_changes;
            this.modalDialogComponent.message = this.localizations['appeals.subservices'].apply_changes;
            this.modalDialogComponent.showModal();

            return this.modalDialogComponent.onSelect
                .pipe(take(1), map((answer: string) => this.canDeactivateByAnswer(answer)))
                .toPromise();
        } else {
            return Promise.resolve(true);
        }
    }

    /**
     * Проверкаа наличия изменений в услуге
     */
    public checkAppealSubserviceChange(): boolean {
        let hasChange = false;
        /*if (this.appeal.subservice.id === this.editAppealSubservice.id) {
            throw new Error('Сабсервис не найден');
        }*/

        const compareKeys = ['subjects', 'objects', 'xsdData', 'variantXsdData'];
        const currData = pick(this.appealSubservicesService.data[this.editAppealSubservice.guid], compareKeys);
        const tempData = pick(this.appealSubservicesService.tempData[this.editAppealSubservice.guid], compareKeys);

        const isEqualData = isEqual(currData, tempData);
        const isEqualVariant = isEqual(this.appeal.subservice.variant, this.editAppealSubservice.variant);

        hasChange = !isEqualData || !isEqualVariant;

        if (!hasChange) {
            this.appeal.objects.forEach(object => {
                const hasChangeInAppealSubservice = this.appealSubservicesService.checkChangeEntityDataInAppealSubservice(this.editAppealSubservice, object);

                hasChange = hasChangeInAppealSubservice ? hasChangeInAppealSubservice : hasChange;
            });
        }

        return hasChange;
    }

    /**
     * Удаление услуги из состава услуг комплексного дела
     * @param appealSubservice
     */
    public async deleteSubservice(appealSubservice) {
        // Удаление документов, которые относятся к удаляемой услуге
        const documents = [];
        this.appeal.documents.forEach(document => {
            if (!document.subserviceGuid || document.subserviceGuid && document.subserviceGuid !== appealSubservice.guid) {
                documents.push(document);
            }
        });
        this.appeal.documents = documents;

        // Удаление в документах ссылки на другую услугу (если она как раз удаляется)
        this.appeal.documents.forEach(document => {
            if (document.resultSubserviceLink && document.resultSubserviceLink.guid === appealSubservice.guid) {
                document.resultSubserviceLink = null;
            }
        });

        // Удаление ссылок на эту услугу, если она была выбрана в качестве родительской
        if (this.appeal.subservice.startAfterGuids && this.appeal.subservice.startAfterGuids.length) {
            this.appeal.subservice.startAfterGuids = this.appeal.subservice.startAfterGuids.filter(item => item !== appealSubservice.guid);
        }

        // Удаление самой услуги из состава дела
        // TODO нужно ли что-то тут делать?

        // Сохранение дела с учетом изменений
        if (this.appeal._id) {
            try {
                await this.saveService.saveAppeal();
                this._afterDeleteSubservice();
            } catch (error) {
                this.toaster.error(error);
            }

            return;
        }

        this._afterDeleteSubservice();
    }

    /**
     * Операции после процедуры удаления услуги из дела
     */
    private _afterDeleteSubservice() {
        // Корректировка данных сервисов
        this.saveService.correctServiceDataAfterDeleteAppealEntity();
        this.toaster.success('Услуга успешно удалена из состава дела');
    }

    /**
     * Переход в режим редактирования настроек дела (контролирующие операторы, комментарий и др.)
     */
    public editAppealSettings() {
        this.isEditAppealSettings = true;
    }

    /**
     * Выход из режима редактирования настроек дела
     */
    public afterEditAppealSettings() {
        this.isEditAppealSettings = false;
    }

    /**
     * Выбор дополнительных услуг для формирования комплексного дела
     */
    public selectOtherSubservices() {
        this.appealService.isProcessSetupAppealSubservices = true;
        this.appealService.selectedSubservicesForSetupInAppeal = [];
        this.router.navigate(['/ais/appeals/services']);
    }

    // ------------------------------------------------------------------- //
    // --------------- Настройка параметров услуг ------------------------ //
    // ------------------------------------------------------------------- //

    /**
     * Переход в режим настройки услуг в деле
     */
    public setupAppealSubservices() {
        this._generateAppealSubservicesStructure();
        this.isProcessSetupSubservicesInAppeal = true;
    }

    /**
     * Формирование структуры услуг в деле для настройки порядка отображения и последовательности исполнения
     */
    private _generateAppealSubservicesStructure() {
        this.subservicesStructure = []; // настройки услуг в деле
        this.currentAppealSubservicesGuids = [];

        // 1. Обработка услуг ранее добавленных в дело
        let displayOrder = 0;
        if (this.appeal.subservice) {
            // Формирование основных параметров
            displayOrder++;
            this.subservicesStructure.push({
                id: this.appeal.subservice.id,
                guid: CommonUtilities.GenerateGuid(), // временный guid, который будет являться идентификатором при работе внутри блока настройки
                guidInAppeal: this.appeal.subservice.guid, // guid услуги в деле
                title: this.appeal.subservice.shortTitle,
                after: this.appeal.subservice.startAfterGuids, // заданная в услуге зависимость
                afterSubservices: [],
                displayOrder: displayOrder,
            });
            // Формирование массива guid-ов услуг, которые есть в деле (для обработки возможно удаленных в процессе настройки услуг)
            this.currentAppealSubservicesGuids.push(this.appeal.subservice.guid);

            // Добавление зависимостей от других услуг
            if (this.appeal.subservice.startAfterGuids && this.appeal.subservice.startAfterGuids.length) {
                const correctSubservice = this.subservicesStructure.find(item => item.guidInAppeal === this.appeal.subservice.guid);
                this.appeal.subservice.startAfterGuids.forEach(appealGuid => {
                    const findParent = this.subservicesStructure.find(item => item.guidInAppeal === appealGuid);
                    correctSubservice.afterSubservices.push({
                        guid: findParent.guid,
                        title: findParent.title,
                        selected: true,
                    });
                });
            }
        } else if (this.appeal.complexSubservice) {
            // 2. Случай создания дела на основе комплексной услуги
            // Добавление базовой информации об объекте
            this.appealService.complexSubservice.subservices.forEach(subservice => {
                displayOrder++;
                this.subservicesStructure.push({
                    id: subservice.id,
                    guid: CommonUtilities.GenerateGuid(),
                    guidInAppeal: null,
                    title: subservice.name,
                    after: subservice.startAfter, // заданные в комплексной услуги ID услуг от которых зависит текущая
                    afterSubservices: [], // массив элементов для отображения услуг, от которых зависит текущая
                    displayOrder: displayOrder,
                    // displayOrder: parseInt(subservice.displayOrder, 10) + 1
                });
            });
            // Добавление информации о услугах, от которых зависит текущая, для последующей настройки
            this.subservicesStructure.forEach(subservice => {
                if (subservice.after && subservice.after.length > 0) {
                    subservice.after.forEach(parentSubserviceId => {
                        const find = this.subservicesStructure.find(item => item.id === parentSubserviceId);
                        if (find) {
                            subservice.afterSubservices.push({
                                guid: find.guid,
                                title: find.title,
                                displayOrder: find.displayOrder,
                            });
                        }
                    });
                }
            });
        }

        // Обработка услуг, дополнительно добавленных из общего списка (помещаются в конец общего списка)
        if (this.appealService.isProcessSetupAppealSubservices && this.appealService.selectedSubservicesForSetupInAppeal.length > 0) {
            this.appealService.selectedSubservicesForSetupInAppeal.forEach(subservice => {
                displayOrder++;
                this.subservicesStructure.push({
                    title: subservice.title,
                    id: subservice.id,
                    guid: CommonUtilities.GenerateGuid(),
                    guidInAppeal: null,
                    after: [],
                    afterSubservices: [],
                    displayOrder: displayOrder,
                });
            });
        }

        // Определение услуг, которые могут быть выбраны как родительские
        this.subservicesStructure.forEach(subservice => {
            this._getSubservicesForParent(subservice);
        });

        this.appealService.isProcessSetupAppealSubservices = false;
        this.appealService.selectedSubservicesForSetupInAppeal = [];
    }

    /**
     * Применение изменений в настройках порядка вывода и зависимостей услуг дела
     * По идее, в subservicesStructure будет только один элемент, и произойдёт только запись (но не перезапись)
     */
    public async applySubserviceStructure() {
        let promises = [];
        const subservicesToGet = [];

        // Формирование массива промисов для запроса услуг с сервера
        this.subservicesStructure.forEach(subservice => {
            if (!this.appealService.subservice._id === subservice.id && !subservicesToGet.includes(subservice.id)) {
                subservicesToGet.push(subservice.id);
                promises.push(this.restService.find(SUBSERVICES_COLLECTION, subservice.id));
            }
        });

        // Получение данных из основной коллекции subservices
        const subservices = await Promise.all(promises);
        promises = [];
        subservices.forEach((subservice, index) => {
            if (!subservice) {
                // Услуга не найдена в основной коллекции, требуется доступ в архивной коллекции
                promises.push(this.restService.find(SUBSERVICES_ARCHIVE_COLLECTION, subservicesToGet[index]));
            } else {
                // Добавление услуги в массив уже имеющихся услуг
                const correctedSubservice = this.appealService.correctSubserviceByCurrentUnit(subservice);
                // TODO а будет ли здесь заполняться?
                if (this.subservice && Object.keys(this.subservice).length) {
                    throw new Error(`Попытка переназначить subservice с ${ this.subservice.id } на ${ correctedSubservice.id }`);
                }

                this.appeal.subservice = correctedSubservice;
            }
        });

        // Получение данных из архивной коллекции subservicesArchive
        if (promises.length) {
            const archiveSubservices = await Promise.all(promises);
            archiveSubservices.forEach(subservice => {
                const correctedSubservice = this.appealService.correctSubserviceByCurrentUnit(subservice);

                // TODO а будет ли здесь заполняться?
                if (this.subservice && Object.keys(this.subservice).length) {
                    throw new Error(`Попытка переназначить subservice с ${ this.subservice.id } на ${ correctedSubservice.id }`);
                }

                this.appeal.subservice = correctedSubservice;
            });
        }

        // Финальная обработка: проставление свойств displayOrder и startAfterGuids
        this._generateResultSubserviceStructure();
        // Переключение флагов завершения обработки и переход к отображению карточек услуг
        await this._afterCompleteSelectSubservices();
    }

    /**
     * Добавление параметров displayOrder и startAfterGuids в соответствии с произведенными настройками
     */
    private _generateResultSubserviceStructure() {
        this.subservicesStructure.forEach(async subserviceData => {
            // let indexSubserviceInAppeal;
            if (subserviceData.guidInAppeal) {
                // Услуга уже описана в деле, меняем только порядок в списке и зависиомость от других услуг
                // indexSubserviceInAppeal = this.appeal.subservices.findIndex(item => item.guid === subserviceData.guidInAppeal);
            } else {
                if (this.subservice._id !== subserviceData.id) {
                    throw new Error('Сабсервис не найден');
                }
                // Новая услуга, добавляем в структуру дела
                const initiatedSubservice = await this.appealService.initSubserviceInAppeal(this.subservice);

                if (this.appeal.subservice && Object.keys(this.appeal.subservice).length) {
                    throw new Error(`Попытка переназначить subservice с ${ this.appeal.subservice.id } на ${ initiatedSubservice.id }`);
                }

                this.appeal.subservice = initiatedSubservice;
                subserviceData.guidInAppeal = this.appeal.subservice.guid;

            }
            this.appeal.subservice.displayOrder = subserviceData.displayOrder;
        });

        this.subservicesStructure.forEach(subserviceData => {
            if (this.appeal.subservice.guid !== subserviceData.guidInAppeal) {
                throw new Error('Сабсервис не найден');
            }
            if (this.appeal.subservice.afterSubservices && this.appeal.subservice.afterSubservices.length > 0) {
                this.appeal.subservice.startAfterGuids = [];
                subserviceData.afterSubservices.forEach(parent => {
                    const findParent = this.subservicesStructure.find(item => item.guid === parent.guid);
                    this.appeal.subservice.startAfterGuids.push(findParent.guidInAppeal);
                });
            } else {
                delete this.appeal.subservice.startAfterGuids;
            }
        });

        // Обработка удаленных
        this.currentAppealSubservicesGuids.forEach(async subserviceGuid => {
            const structureHasSubservice = this.subservicesStructure.some(item => item.guidInAppeal === subserviceGuid);
            if (!structureHasSubservice && this.appeal.subservice.guid === subserviceGuid) {
                await this.deleteSubservice(this.appeal.subservice);
            }
        });
    }

    /**
     * Удаление услуги при настройке параметров услуг
     * @param subservice
     */
    public setupDeleteSubservice(subservice) {
        // Удаляем все ссылки в зависимостях на эту услугу
        const deletedGuid = subservice.guid;
        this.subservicesStructure = this.subservicesStructure.filter(item => item.guid !== subservice.guid);
        // Перерасчет номеров услуг в деле
        let displayOrder = 0;
        this.subservicesStructure.forEach(item => {
            displayOrder++;
            item.displayOrder = displayOrder;
            // Удаление услуги из списка зависимых
            if (item.afterSubservices && item.afterSubservices.length) {
                item.afterSubservices = item.afterSubservices.filter(elm => elm.guid !== deletedGuid);
            }
        });
    }

    /**
     * Инициализация данных в сервисах после завершения режима настройки услуг в деле
     */
    private async _afterCompleteSelectSubservices() {
        // Сортируем услуги в деле в соответствии с порядком вывода
        this.isProcessSetupSubservicesInAppeal = false;
        // Сбрасываем параметры выбора услуг со вкладки "Все услуги"
        this.appealService.isProcessSetupAppealSubservices = false;
        this.appealService.selectedSubservicesForSetupInAppeal = [];

        // Инициализируем повторно данные сервисов
        this.appealSubservicesService.initData(this.appeal, this.subservice);
        this.appealService.subservice = this.subservice;
        this.documentService.initData(this.appeal, this.subservice);

        // Сохраняем данные
        if (this.appeal._id) {
            await this.saveService.saveAppeal();
        }
    }

    /**
     * Событие начала перетаскивания элемента для сортировки
     * @param event
     * @param item
     */
    public dragStart(event, item) {
        event.dataTransfer.setData('text', item.guid);
        item.isDrag = true;
    }

    /**
     * Инициализация элемента над которым находится перетаскиваемый элемент
     * @param event
     * @param item
     */
    public dragOver(event, item) {
        event.preventDefault();
        item.isOver = true;
    }

    /**
     * Событие потери фокуса перетаскиваемого элемента над обрабатываемым элементом
     * @param event
     * @param item
     */
    public dragLeave(event, item) {
        event.preventDefault();
        item.isOver = false;
    }

    /**
     * Событие финального выбора позиции элемента при перетаскивании
     * @param event
     * @param subservice
     */
    public drop(event, subservice) {
        event.preventDefault();
        const droppedGuid = event.dataTransfer.getData('text'); // GUID перетаскиваемого элемента
        const droppedSubservice = this.subservicesStructure.find(item => item.guid === droppedGuid); // перетаскиваемый объект услуги
        const indexToMove = this.subservicesStructure.findIndex(item => item.guid === subservice.guid);
        const resultItems = [];

        this.subservicesStructure.forEach((item, idx) => {
            item.isDrag = false;
            item.isOver = false;
            // Случай когда элемент остался в той же позиции
            if (droppedGuid === subservice.guid) {
                resultItems.push(item);
            } else if (item.guid !== droppedGuid) {
                if (idx === indexToMove) {
                    resultItems.push(droppedSubservice);
                }
                resultItems.push(item);
            }
        });

        resultItems.forEach((item, idx) => {
            item.displayOrder = idx + 1;
        });

        // Корректируем номера зависимых услуг
        resultItems.forEach(resultSubservice => {
            if (resultSubservice.afterSubservices && resultSubservice.afterSubservices.length > 0) {
                resultSubservice.afterSubservices.forEach(afterSubservice => {
                    const find = resultItems.find(item => item.guid === afterSubservice.guid);
                    afterSubservice.displayOrder = find.displayOrder;
                });
            }
        });

        this.subservicesStructure = resultItems;
    }

    /**
     * Определение набора услуг, которые можно выбрать в качестве родительской (после которой будет стартовать обрабатываемая услуга)
     * @param checkSubservice - обрабатываемая услуга
     */
    private _getSubservicesForParent(checkSubservice) {
        const result = [];
        this.subservicesStructure.forEach(subservice => {
            // Если это не обрабатываемая услуга
            if (checkSubservice.guid !== subservice.guid) {
                // Проверяем, отображать ли услугу в списке для выбора или нет
                let canUse = true;

                if (subservice.afterSubservices && subservice.afterSubservices.length > 0) {
                    const find = subservice.afterSubservices.find(item => item.guid === checkSubservice.guid);
                    if (find) {
                        // Не отображать услуги, которые являются подчиненными для текущей услуги (учитывая все дочерние)
                        canUse = false;
                    } else {
                        // Услуга не является напрямую зависимой от проверяемой, проверяем по дереву вверх
                        canUse = !this.checkChildSubservices(checkSubservice.guid, subservice.afterSubservices);
                    }
                }

                if (canUse) {
                    result.push({
                        guid: subservice.guid,
                        title: subservice.title,
                        displayOrder: subservice.displayOrder,
                        selected: checkSubservice.afterSubservices.findIndex(item => item.guid === subservice.guid) !== -1,
                    });
                }
            }
        });
        checkSubservice.parentSubservicesForSelect = result;
    }

    /**
     * КНМ на то, что услуга может быть родительской для определенной услуги
     * @param compareGuid - GUID - искомой услуги
     * @param checkSubservices - массив услуг, которая проверяется в данный момент
     */
    public checkChildSubservices(compareGuid, checkSubservices) {
        let isUse = false;
        checkSubservices.forEach(checkSubservice => {
            const subservice = this.subservicesStructure.find(item => item.guid === checkSubservice.guid);
            if (subservice.afterSubservices && subservice.afterSubservices.length > 0) {
                const findCompareInChecked = subservice.afterSubservices.find(item => item.guid === compareGuid);
                if (findCompareInChecked) {
                    isUse = true;
                } else {
                    // Поиск наверх
                    const useInCheck = this.checkChildSubservices(compareGuid, subservice.afterSubservices);
                    isUse = useInCheck ? useInCheck : isUse;
                }
            }
        });

        return isUse;
    }

    /**
     * Применение настроек зависимости услуги от других услуг
     * @param subservice - обрабатываемая услуга
     */
    public applyParentSubservices(subservice) {
        subservice.afterSubservices = subservice.parentSubservicesForSelect.filter(item => item.selected);
        subservice.isProcessSelectParent = false;
        this.subservicesStructure.forEach(item => {
            this._getSubservicesForParent(item);
        });
        this.appealService.editing = false;
    }

    public cancelEditing(subservice) {
        subservice.isProcessSelectParent = false;
        this.appealService.editing = false;
    }

    /**
     * Включение режима настройки зависимости услуг
     * @param subservice
     */
    public changeParentSubservices(subservice) {
        this._getSubservicesForParent(subservice);
        subservice.isProcessSelectParent = true;
    }

    /**
     * Передача управления в режим добавления нового участника в дело
     * @param data
     */
    public async addNewSubjectToAppealSubserviceGroup(subserviceAndGroup) {
        // так как при создании дела компонет учасников - это часть темплейта дела, а при редактировании - он подгружается роутером,
        // то при редактировании приходится роутить на компоент сабджектов и ставить флаг в sessionStorage, чтобы он создал нового пользователя
        if (this.router.url.split('/')[3] !== 'edit') {
            this.onAddNewSubjectToAppealSubserviceGroup.emit(subserviceAndGroup);
        } else {
            this.storage.cacheItem('createNewUser', true);
            await this.router.navigate(['supervisor', 'appeals', 'edit', this.appeal._id, 'subjects']);
        }
    }

    public async gotToLicense(licenseId) {
        await this.router.navigate(['supervisor', this.appealService.registersPath, 'edit', licenseId]);
    }

    /**
     * Разрешение деактивации исходя из ответа в модальном окне о сохранении изменений услуги
     * @param answer - ответ из модального окна (yes, no, cancel)
     */
    private canDeactivateByAnswer(answer: string): boolean {
        if (answer === 'yes') {
            // Сохранение данных и деактивация
            if (this.editAppealSubserviceCardComponent) {
                this.editAppealSubserviceCardComponent.apply();
            }

            return true;
        }

        if (answer === 'no') {
            // Сброс внесенных изменений и деактивация
            if (this.editAppealSubserviceCardComponent) {
                this.editAppealSubserviceCardComponent.cancel();
            }

            return true;
        }

        // Запрет деактивации при отмене
        return false;
    }
}
